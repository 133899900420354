import { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { useToasts } from "../../hooks/useToasts";
import useFeederStartCalibrationMutation from "../../api/mutations/FeederStartCalibration";
import { Feeder } from "../../api";
import { SuccessAlert } from "../app/SuccessAlert";
import { ErrorAlert } from "../app/ErrorAlert";
import FeederTub from "./FeederTub";
import FeederPlatform from "./FeederPlatform";
import FeederProximity from "./FeederProximity";
import FeederRFID from "./FeederRFID";

const CALIBRATION_TIMEOUT = 30;

enum CalibrationState {
  IDLE,
  WAIT,
  CHECK_PROXIMITY,
  CHECK_RFID,
  CALIBRATE_TUB_TARE,
  CALIBRATE_TUB_SCALE,
  CHECK_TUB_TARE,
  CHECK_TUB_SCALE,
  CALIBRATE_PLATFORM_TARE,
  CALIBRATE_PLATFORM_SCALE,
  CHECK_PLATFORM_TARE,
  CHECK_PLATFORM_SCALE,
  COMPLETE,
}

interface FeederCalibrationModalProps {
  onCancel(): void;
  onSuccess(): void;
  feeder: Feeder;
}

export const FeederCalibrationModal = ({
  onCancel,
  onSuccess,
  feeder,
}: FeederCalibrationModalProps) => {
  const [calibrationState, setCalibrationState] = useState(
    CalibrationState.IDLE
  );
  const { mutate: feederStartCalibrationMutation } =
    useFeederStartCalibrationMutation();

  const { newToast } = useToasts();

  useEffect(() => {
    const state = feeder.controller.calibrationState.replaceAll(" ", "_");
    if (state === "IDLE" && calibrationState !== CalibrationState.IDLE) {
      setCalibrationState(CalibrationState.COMPLETE);
    } else {
      setCalibrationState(
        CalibrationState[state as keyof typeof CalibrationState]
      );
    }
  }, [feeder.controller.calibrationState]);

  const sendStartCalibration = async (feeder: Feeder) => {
    try {
      const { data } = await feederStartCalibrationMutation({
        variables: {
          siteId: feeder.site.id,
          feederId: feeder.id,
        },
      });
      if (data.siteStartFeederCalibration.isSuccess) {
        newToast(<SuccessAlert message="Start calibration command sent" />);
        setCalibrationState(CalibrationState.WAIT);
      } else {
        newToast(
          <ErrorAlert message={`${data.siteStartFeederCalibration.message}`} />
        );
      }
      // FIXME:
    } catch (err) {
      newToast(<ErrorAlert message={`${err}`} />);
    }
  };

  return (
    <Modal
      title={`Calibration: ${feeder.site.name} - ${feeder.name}`}
      onClose={() => onCancel()}
    >
      <div>
        <FeederTub feeder={feeder} />
        <br />
        <FeederPlatform feeder={feeder} />
        <br />
        <FeederProximity feeder={feeder} />
        <br />
        <FeederRFID feeder={feeder} />
      </div>
      <div>
        {calibrationState === CalibrationState.IDLE ? (
          <div>
            <div className="text-red-500">
              <h1 className="font-bold text-xl">WARNING</h1>
              Ensure the tub and platform are clean and empty before clicking
              start.
            </div>
            {feeder.controller.state !== "IDLE" ? (
              <div>"Waiting for IDLE"</div>
            ) : (
              ""
            )}
            <button
              onClick={() => sendStartCalibration(feeder as any)}
              disabled={feeder.controller.state !== "IDLE"}
              className="mt-8 w-full mt-4 px-3 py-4 text-white bg-green-800 rounded-md focus:bg-gray-600 focus:outline-none disabled:opacity-50"
            >
              Send Start Message
            </button>
          </div>
        ) : (
          ""
        )}
        {calibrationState === CalibrationState.WAIT ? (
          <div>
            <h1 className="font-bold text-xl">STARTING CALIBRATION</h1>
            <div className="text-gray-900 dark:text-white">Waiting device</div>
          </div>
        ) : (
          ""
        )}
        {calibrationState === CalibrationState.CHECK_PROXIMITY ? (
          <div>
            <div className="text-gray-900 dark:text-white">
              <h1 className="font-bold text-xl">PROXIMITY CHECK</h1>
              Block the proximity sensor
            </div>
          </div>
        ) : (
          ""
        )}
        {calibrationState === CalibrationState.CHECK_RFID ? (
          <div>
            <div className="text-gray-900 dark:text-white">
              <h1 className="font-bold text-xl">RFID CHECK</h1>
              Scan an RFID tag
            </div>
          </div>
        ) : (
          ""
        )}
        {calibrationState === CalibrationState.CALIBRATE_TUB_TARE ? (
          <div>
            <div className="text-gray-900 dark:text-white">
              <h1 className="font-bold text-xl">TUB TARE CALIBRATION</h1>
              Remove all weights from tub
            </div>
          </div>
        ) : (
          ""
        )}
        {calibrationState === CalibrationState.CALIBRATE_TUB_SCALE ? (
          <div>
            <div className="text-gray-900 dark:text-white">
              <h1 className="font-bold text-xl">TUB SCALE CALIBRATION</h1>
              Add the calibration weight to the tub
            </div>
          </div>
        ) : (
          ""
        )}
        {calibrationState === CalibrationState.CHECK_TUB_TARE ? (
          <div>
            <div className="text-gray-900 dark:text-white">
              <h1 className="font-bold text-xl">TUB TARE CHECK</h1>
              Checking tub tare, remove all weights
            </div>
          </div>
        ) : (
          ""
        )}
        {calibrationState === CalibrationState.CHECK_TUB_SCALE ? (
          <div>
            <div className="text-gray-900 dark:text-white">
              <h1 className="font-bold text-xl">TUB SCALE CHECK</h1>
              Checking tub scale, add the calibration weight
            </div>
          </div>
        ) : (
          ""
        )}
        {calibrationState === CalibrationState.CALIBRATE_PLATFORM_TARE ? (
          <div>
            <div className="text-gray-900 dark:text-white">
              <h1 className="font-bold text-xl">PLATFORM TARE CALIBRATION</h1>
              Remove all weights from platform
            </div>
          </div>
        ) : (
          ""
        )}
        {calibrationState === CalibrationState.CALIBRATE_PLATFORM_SCALE ? (
          <div>
            <div className="text-gray-900 dark:text-white">
              <h1 className="font-bold text-xl">PLATFORM SCALE CALIBRATION</h1>
              Add the calibration weight to the platform
            </div>
          </div>
        ) : (
          ""
        )}
        {calibrationState === CalibrationState.CHECK_PLATFORM_TARE ? (
          <div>
            <div className="text-gray-900 dark:text-white">
              <h1 className="font-bold text-xl">PLATFORM TARE CHECK</h1>
              Checking platform tare, remove all weights
            </div>
          </div>
        ) : (
          ""
        )}
        {calibrationState === CalibrationState.CHECK_PLATFORM_SCALE ? (
          <div>
            <div className="text-gray-900 dark:text-white">
              <h1 className="font-bold text-xl">PLATFORM SCALE CHECK</h1>
              Checking platform scale, add the calibration weight
            </div>
          </div>
        ) : (
          ""
        )}
        {calibrationState === CalibrationState.COMPLETE ? (
          <div>
            <div className="text-gray-900 dark:text-white">
              <h1 className="font-bold text-xl">COMPLETE</h1>
              Calibration complete
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </Modal>
  );
};
