interface NumberCardProps {
  name: string;
  value: string | number;
  extra?: string | number;
}

export const NumberCard = ({ name, value, extra = "" }: NumberCardProps) => {
  return (
    <div className="p-3 rounded-2xl bg-white dark:bg-gray-800 dark:shadow-white">
      <div className="space-y-1">
        <div className="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500 dark:text-white">
          <span>{name}</span>
        </div>
        <div className="text-2xl text-gray-800 dark:text-white">
          {value} <span className="text-sm font-medium">{extra}</span>
        </div>{" "}
        {/* {extra ? (
          <div className="flex items-center space-x-1 rtl:space-x-reverse text-sm font-medium">
            <span>{extra}</span>
          </div>
        ) : (
          ""
        )} */}
      </div>
    </div>
  );
};
