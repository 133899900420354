// import moment from "moment-timezone";
import { Parser as CSVParser } from "@json2csv/plainjs";
import { saveAs } from "file-saver";

export const downloadCSV = (
  records: any[],
  fields: any[],
  filename: string
) => {
  const csvParser = new CSVParser({ fields });
  const csvData = csvParser.parse(records);

  const blob = new Blob([csvData], { type: "application/csv;charset=utf-8" });
  saveAs(blob, filename);
};
