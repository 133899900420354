import { Feeder } from '../../api/interfaces';

interface FeederProximityProps {
  feeder: Feeder;
}

export const FeederProximity = ({ feeder }: FeederProximityProps) => {
  if (!feeder.controller) return <></>;

  const distance = (feeder.controller.proximityDistance / 10).toFixed(1);

  switch (feeder.controller.proximityState) {
    case 'DETECTED':
      return (
        <div className="text-sm text-center text-purple-500 dark:text-white">
          <span className="border border-purple-500 px-2">{distance}</span>
          <div>Triggered</div>
        </div>
      );
    case 'CLEAR':
      return (
        <div className="text-sm text-center text-green-500 dark:text-white">
          <span className="border border-green-500 px-2">{distance}</span>
          <div>Clear</div>
        </div>
      );
    case 'ERROR_STUCK':
      return (
        <div className="text-sm text-center text-red-500 dark:text-white">
          <div className="border border-red-500 px-2">{distance}</div>
          <div>Stuck</div>
        </div>
      );
    default:
      return (
        <div className="text-sm text-center text-red-500 dark:text-white">
          <span className="border border-red-500 px-2">{distance}</span>
          <div>Error</div>
        </div>
      );
  }
};

export default FeederProximity;
