/**
 * A query to get a site by its id.
 */

import { gql, useQuery } from "@apollo/client";
import { Site } from "../interfaces";

export const GET_SITE_BY_ID = gql`
  query GetSiteById($id: ID!) {
    siteById(id: $id) {
      isSuccess
      message
      site {
        id
        name
        roles {
          id
          name
        }
        trials {
          id
          startDate
          endDate
          site {
            id
          }
          pens {
            pen {
              id
              name
            }
          }
        }
      }
    }
  }
`;

interface GetSitesByIdInput {
  id: string;
}

export interface GetSitesByIdResponse {
  siteById: {
    isSuccess: boolean;
    message: string;
    site: Site;
  };
}

export const useQueryGetSiteById = (id: string) => {
  let site: Site = null;
  const { loading, data, error } = useQuery<
    GetSitesByIdResponse,
    GetSitesByIdInput
  >(GET_SITE_BY_ID, { variables: { id } });

  if (!loading && !error && data) {
    site = data.siteById.site;
  }

  return {
    loading,
    error,
    response: {
      isSuccess: data ? data.siteById.isSuccess : false,
      message: data ? data.siteById.message : "See error",
      site,
    },
  };
};

export default useQueryGetSiteById;
