import { ReactElement } from 'react';

export interface ColumnProps<T> {
  key: string;
  title: string | ReactElement;
  render?: (column: ColumnProps<T>, item: T) => ReactElement;
}

export interface DataTableProps<T> {
  columns: Array<ColumnProps<T>>;
  data?: T[];
}

export const DataTable = <T,>({ data, columns }: DataTableProps<T>) => {
  const headers = columns.map((column, index) => {
    return (
      <th
        key={`headCell-${index}`}
        className="text-center px-5 py-3 border-b-2 bg-white border-gray-200 text-left text-sm font-bold text-gray-900  dark:bg-gray-800 dark:text-white"
      >
        {column.title}
      </th>
    );
  });

  const rows = !data?.length ? (
    <tr className="hover:bg-gray-200">
      <td
        colSpan={columns.length}
        className="text-center hover:bg-gray-50 bg-white dark:bg-gray-800 dark:text-white"
      >
        No data
      </td>
    </tr>
  ) : (
    data?.map((row, index) => {
      return (
        <tr
          key={`row-${index}`}
          className="text-center hover:bg-gray-50 bg-white dark:bg-gray-800 dark:text-white"
        >
          {columns.map((column, index2) => {
            const value = column.render
              ? column.render(column, row as T)
              : (row[column.key as keyof typeof row] as string);

            return (
              <td
                key={`cell-${index2}`}
                className="px-5 py-1 border-b border-gray-200 text-sm"
              >
                {value}
              </td>
            );
          })}
        </tr>
      );
    })
  );

  return (
    <div className="inline-block min-w-full overflow-x-auto">
      <table className="table min-w-full leading-normal">
        <thead className="bg-slate-400 text-black">
          <tr>{headers}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};
