import moment from "moment-timezone";
import { useState } from "react";

interface TrialDatePickerProps {
  startDate: Date;
  endDate: Date;
  onChange(startDate: Date, endDate: Date): void;
}

export const TrialDatePicker = ({
  startDate,
  endDate,
  onChange,
}: TrialDatePickerProps) => {
  const [selectedStartDate, setSelectedStartDate] = useState(moment(startDate));
  const [selectedEndDate, setSelectedEndDate] = useState(moment(endDate));
  const [minStartDate] = useState(moment(startDate));
  const [maxStartDate, setMaxStartDate] = useState(
    moment(endDate).subtract(1, "days")
  );
  const [maxEndDate] = useState(moment(endDate));
  const [minEndDate, setMinEndDate] = useState(
    moment(startDate).add(1, "days")
  );

  const onChangeStartDate = (sDate: string) => {
    const theDate = moment(sDate);
    setSelectedStartDate(theDate);
    onChange(theDate.toDate(), selectedEndDate.toDate());
    setMinEndDate(theDate.clone().add(1, "days"));
  };

  const onChangeEndDate = (sDate: string) => {
    const theDate = moment(sDate);
    setSelectedEndDate(theDate);
    onChange(selectedStartDate.toDate(), theDate.toDate());
    setMaxStartDate(theDate.clone().subtract(1, "days"));
  };

  return (
    <div className="grid grid grid-cols-12 gap-6 mb-4">
      <div className="col-span-6 sm:col-span-6">
        <label
          htmlFor="startDate"
          className="block mb-2 text-sm text-gray-900 dark:text-white"
        >
          Start Date
        </label>
        <input
          autoFocus
          autoComplete="off"
          tabIndex={1}
          type="date"
          min={minStartDate.format("YYYY-MM-DD")}
          max={maxStartDate.format("YYYY-MM-DD")}
          value={selectedStartDate.format("YYYY-MM-DD")}
          onChange={(e) => onChangeStartDate(e.target.value)}
          id="startDate"
          className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-gray-400 dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
        />
      </div>
      <div className="col-span-6 sm:col-span-6">
        <label
          htmlFor="endDate"
          className="block mb-2 text-sm text-gray-900 dark:text-white"
        >
          End Date
        </label>
        <input
          autoComplete="off"
          tabIndex={2}
          type="date"
          min={minEndDate.format("YYYY-MM-DD")}
          max={maxEndDate.format("YYYY-MM-DD")}
          value={selectedEndDate.format("YYYY-MM-DD")}
          onChange={(e) => onChangeEndDate(e.target.value)}
          id="endDate"
          className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-gray-400 dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
        />
      </div>
    </div>
  );
};
