import { useState } from "react";
import moment from "moment-timezone";
import { Link, useNavigate, useParams } from "react-router-dom";

import PageWrapper from "../components/app/PageWrapper";
import TrialDownloadModal from "../components/elements/TrialDownloadModal";
import { DeviceIcon, AddIcon } from "../components/icons";
import { useQueryGetSiteById } from "../api";
import TrialStatus from "../components/elements/TrialStatus";
import TrialAddModal from "../components/elements/TrialAddModal";
import { Site } from "../api";
import { LoadingComponent } from "../components/elements/LoadingComponent";
import { ErrorComponent } from "../components/elements/ErrorComponent";
import { useToasts } from "../hooks/useToasts";
import { SuccessAlert } from "../components/app/SuccessAlert";
import { TrialList } from "../components/lists/TrialList";

export const TrialsPage = () => {
  const params = useParams();
  const siteId = params.siteId;
  const { newToast } = useToasts();

  const { loading, error, response } = useQueryGetSiteById(siteId);

  const [showTrialDownloadModal, setShowTrialDownloadModal] = useState(false);
  const [selectedTrial, setSelectedTrial] = useState(null);
  const [showAddTrialModal, setShowAddTrialModal] = useState(false);

  const navigate = useNavigate();

  const onClickViewDashboard = (trial: any) => {
    navigate(`/sites/${siteId}/trials/${trial.id}/dashboard`);
  };

  const onClickManageFeeders = () => {
    navigate(`/sites/${siteId}/feeders`);
  };

  const onClickDownload = (trial: any) => {
    setSelectedTrial(trial);
    setShowTrialDownloadModal(true);
  };

  const onCancelAddTrial = () => {
    setShowAddTrialModal(false);
  };

  const onClickAddTrial = () => {
    setShowAddTrialModal(true);
  };

  const isSiteAdmin = (site: Site) => {
    return site.roles.find((r: any) => r.name === "site-admin");
  };

  if (loading) {
    return <LoadingComponent></LoadingComponent>;
  }

  if (error || !response.isSuccess) {
    return (
      <ErrorComponent error={error} message={response.message}></ErrorComponent>
    );
  }

  const site = response.site;

  return (
    <PageWrapper>
      <div className="mt-1 ml-4">
        {showTrialDownloadModal ? (
          <TrialDownloadModal
            onClose={() => {
              setShowTrialDownloadModal(false);
            }}
            trialId={selectedTrial.id}
          ></TrialDownloadModal>
        ) : (
          ""
        )}
        {showAddTrialModal ? (
          <TrialAddModal
            onCancel={onCancelAddTrial}
            onSuccess={(trial) => {
              setShowAddTrialModal(false);
              newToast(
                <SuccessAlert message={`Added new trial ${trial.id}`} />,
                5000
              );
            }}
          ></TrialAddModal>
        ) : (
          ""
        )}

        <div className="flex">
          <div className="flex">
            <div className=" flex items-center justify-between pb-3">
              <div>
                <h2 className="text-gray-600">
                  <Link className="cursor-pointer font-semibold" to="/sites">
                    Sites:
                  </Link>{" "}
                  <Link
                    className="cursor-pointer font-semibold"
                    to={`/sites/${site.id}`}
                  >
                    {site.name}:
                  </Link>{" "}
                  Trials
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex ml-auto mr-4">
            {isSiteAdmin(site) ? (
              <span
                onClick={() => onClickManageFeeders()}
                className="ml-2 text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500  cursor-pointer"
              >
                <DeviceIcon></DeviceIcon> Manage Feeders
              </span>
            ) : (
              ""
            )}

            {isSiteAdmin(site) ? (
              <span
                onClick={() => onClickAddTrial()}
                className="ml-2 text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500 cursor-pointer"
              >
                <AddIcon></AddIcon> Add Trial
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <TrialList site={site} trials={site.trials}></TrialList>
      </div>
    </PageWrapper>
  );
};

export default TrialsPage;
