import { useNavigate } from "react-router-dom";
import { Site } from "../../api";

export interface SiteListProps {
  sites: Site[];
}

export const SiteList = ({ sites }: SiteListProps) => {
  const navigate = useNavigate();

  const onClickSite = (site: Site) => {
    navigate(`/sites/${site.id}`);
  };

  return (
    <div className="bg-white rounded-md w-full">
      <div>
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Timezone
                  </th>
                </tr>
              </thead>
              <tbody>
                {sites.map((site) => (
                  <tr
                    className="cursor-pointer"
                    key={site.id}
                    onClick={() => onClickSite(site)}
                  >
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="flex items-center">
                        <div className="ml-3">
                          <div className="text-gray-900 whitespace-no-wrap font-bold">
                            {site.name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {site.timezone}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
