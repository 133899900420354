import PageWrapper from "../app/PageWrapper";

export const LoadingComponent = () => {
  return (
    <PageWrapper>
      <div className="mx-4 my-4">
        <div className="flex flex-col text-md text-gray-900 dark:text-white ">
          <h1 className="text-xl font-bold">Loading</h1>
          <div>LOADING...</div>
        </div>
      </div>
    </PageWrapper>
  );
};
