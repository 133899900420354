import React, { useState } from "react";
import { useLoginMutation, useLogoutMutation } from "../api";

import { User } from "../api/interfaces";

export const AUTH_USER_KEY = "AUTH_USER";
export const AUTH_TOKEN_KEY = "AUTH_TOKEN";

type AuthContextState = {
  isAuthenticated: boolean;
  currentUser: User;
  login(email: string, password: string): Promise<User>;
  logout(): Promise<void>;
  updateUser(user: User): void;
};

const defaultContext: AuthContextState = {
  isAuthenticated: false,
  currentUser: null,
  login: () => {
    return null;
  },
  logout: () => {
    return null;
  },
  updateUser: () => {},
};

export const clearAuthTokens = () => {
  localStorage.removeItem(AUTH_USER_KEY);
  localStorage.removeItem(AUTH_TOKEN_KEY);
};

export const saveUser = (user: User) => {
  localStorage.setItem(AUTH_USER_KEY, JSON.stringify(user));
};

const useProvideAuth = (): AuthContextState => {
  const rawUser = localStorage.getItem(AUTH_USER_KEY);

  let user: User = null;

  try {
    if (rawUser) {
      user = JSON.parse(rawUser);
    }
  } catch {}

  const [currentUser, setCurrentUser] = useState<User>(user);
  const [isAuthenticated, setIsAuthenticated] = useState(!!user);

  const { mutate: loginMutation } = useLoginMutation();
  const { mutate: logoutMutation } = useLogoutMutation();

  /**
   * Login using the API
   * @param email
   * @param password
   * @returns
   */
  const login = async (email: string, password: string) => {
    try {
      const {
        data: {
          userLogin: { isSuccess, message, user, token },
        },
      } = await loginMutation({ variables: { email, password } });

      if (!isSuccess) {
        throw new Error(message);
      }

      setCurrentUser(user);
      setIsAuthenticated(true);
      saveUser(user);
      localStorage.setItem(AUTH_TOKEN_KEY, token);

      return user;
    } catch (err) {
      // console.error(err);
      throw err;
    }
  };

  const updateUser = (user: User) => {
    setCurrentUser(user);
    saveUser(user);
  };

  const logout = async () => {
    console.debug("[logout]");
    try {
      const {
        data: {
          userLogout: { isSuccess, message },
        },
      } = await logoutMutation();

      if (!isSuccess) {
        throw new Error(message);
      }
    } catch (err) {
      console.error(err);
      // throw err;
    }
    console.debug("[logout]");
    setIsAuthenticated(false);
    clearAuthTokens();
  };

  return {
    isAuthenticated,
    currentUser,
    updateUser,
    login,
    logout,
  };
};

// Create a context for sharing across the App
export const AuthContext =
  React.createContext<AuthContextState>(defaultContext);

// Wrapper for the provider
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

// the hook
export const useAuth = () => React.useContext(AuthContext);
