import { Feeder } from '../../api/interfaces';

interface FeederRFIDProps {
  feeder: Feeder;
}

export const FeederRFID = ({ feeder }: FeederRFIDProps) => {
  if (!feeder.controller) return <></>;

  const mof = feeder.controller.rfidMOF / 10;

  let mofDisplay = (
    <span className="text-green-500 dark:text-white">MOF OK ({mof})</span>
  );

  const isMOFValid = (mof: number) => {
    return mof >= 115 && mof <= 135;
  };

  if (!isMOFValid(mof)) {
    mofDisplay = <span className="text-red-500">MOF OOR ({mof})</span>;
  }

  switch (feeder.controller.rfidState) {
    case 'DISABLED':
      return (
        <div className="text-sm text-center text-green-500 dark:text-white">
          <span className="border border-green-500 px-2">
            {feeder.controller.rfidTag ? feeder.controller.rfidTag : 'NO TAG'}
          </span>
          <div>{mofDisplay}</div>
        </div>
      );
    case 'ENABLED':
      return (
        <div className="text-sm text-center text-purple-500 dark:text-white">
          <span className="border border-purple-500 px-2">
            {feeder.controller.rfidTag ? feeder.controller.rfidTag : 'NO TAG'}
          </span>
          <div>{mofDisplay}</div>
        </div>
      );
    default:
      return (
        <div className="text-sm text-center text-red-500 dark:text-white">
          <span className="border border-red-500 px-2">Error</span>
          <div>{mofDisplay}</div>
        </div>
      );
  }
};

export default FeederRFID;
