import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { ArrowIcon } from "../icons/ArrowIcon";
import SupportModal from "../elements/SupportModal";
import UserDetailsModal from "../elements/UserDetailsModal";
import { useTheme, Theme } from "../../hooks/useTheme";

export const UserDropdown = () => {
  const { currentUser, logout } = useAuth();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
  const { theme, setTheme } = useTheme();

  const ref = useRef();

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      if (ref.current && !(ref.current as any).contains(event.target)) {
        setShowDropdown(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const toggleTheme = (theme: Theme) => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  const onClick = () => {
    setShowDropdown(!showDropdown);
  };

  const onClickLogout = async () => {
    await logout();
  };

  return (
    <div ref={ref} className="relative inline-block text-left">
      {showSupportModal ? (
        <SupportModal
          onSuccess={() => setShowSupportModal(false)}
          onCancel={() => setShowSupportModal(false)}
        ></SupportModal>
      ) : (
        ""
      )}
      {showUserDetailsModal ? (
        <UserDetailsModal
          user={currentUser}
          onSuccess={() => setShowUserDetailsModal(false)}
          onCancel={() => setShowUserDetailsModal(false)}
        ></UserDetailsModal>
      ) : (
        ""
      )}
      <div>
        <button
          type="button"
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white dark:bg-gray-800 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={onClick}
        >
          {currentUser.firstName} {currentUser.lastName}
          <ArrowIcon></ArrowIcon>
        </button>
      </div>
      {showDropdown ? (
        <div
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black dark:ring-gray-700 ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          <div className="py-1" role="none">
            <button
              className="block px-4 py-2 text-sm text-gray-700 dark:text-white"
              role="menuitem"
              tabIndex={-1}
              id="menu-item-0"
              onClick={() => {
                setShowDropdown(false);
                setShowUserDetailsModal(true);
              }}
            >
              Profile and Password
            </button>
            {currentUser.isAdmin ? (
              <Link
                to="/admin"
                className="block px-4 py-2 text-sm text-gray-700 dark:text-white"
                role="menuitem"
                tabIndex={-1}
                id="menu-item-1"
              >
                MASTERyard Admin
              </Link>
            ) : (
              ""
            )}
            <button
              className="block px-4 py-2 text-sm text-gray-700 dark:text-white"
              role="menuitem"
              tabIndex={-1}
              id="menu-item-1"
              onClick={() => {
                setShowDropdown(false);
                setShowSupportModal(true);
              }}
            >
              Request support
            </button>
            <button
              className="block px-4 py-2 text-sm text-gray-700 dark:text-white"
              role="menuitem"
              tabIndex={-1}
              id="menu-item-1"
              onClick={() => {
                setShowDropdown(false);
                toggleTheme(theme);
              }}
            >
              {theme === "dark" ? "Light theme" : "Dark Theme"}
            </button>
            <Link
              to="#"
              onClick={onClickLogout}
              className="block px-4 py-2 text-sm text-gray-700 dark:text-white"
              role="menuitem"
              tabIndex={-1}
              id="menu-item-3"
            >
              Sign out
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
