/**
 * A form for resettting a password.
 */
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../hooks/useAuth";

export interface UserInput {
  email: string;
  password: string;
  passwordConfirmation: string;
}

interface Props {
  id: string;
  onSave(user: UserInput): void;
}

// Validation schema for the form inputs
const schema = Yup.object().shape({
  email: Yup.string(),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum."),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export const ResetPasswordForm = ({ id, onSave }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<UserInput>({
    mode: "onSubmit",
    resolver: yupResolver(schema) as any,
  });

  const { currentUser } = useAuth();

  const onSubmit = handleSubmit((data) => {
    userData.password = data.password;
    onSave(userData);
  });

  const [userData] = useState<UserInput>({
    email: currentUser.email,
    password: "",
    passwordConfirmation: "",
  });

  return (
    <form id={id} className="w-fullm-auto py-2 mt-2 px-2" onSubmit={onSubmit}>
      <h1 className="text-gray-900 dark:text-white mb-4">
        A password reset is required.
      </h1>
      <div className="grid grid grid-cols-12 gap-6 mb-4">
        <div className="col-span-12 sm:col-span-12">
          <label
            htmlFor="email"
            className="block mb-2 text-sm  text-gray-900 dark:text-white"
          >
            Email Address
          </label>
          <input
            autoFocus
            autoComplete="new-password"
            {...register("email")}
            tabIndex={1}
            type="email"
            id="email"
            defaultValue={userData.email}
            disabled={true}
            placeholder="you@company.com"
            className="block px-4 w-full bg-white rounded-md border-solid border-gray-300 border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-9 disabled:opacity-50  dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
          />
          <p className="text-red-500">{errors.email?.message}</p>
        </div>
        <div className="col-span-6 sm:col-span-6">
          <label
            htmlFor="lastName"
            className="block mb-2 text-sm text-gray-900 dark:text-white"
          >
            Password
          </label>
          <input
            autoComplete="new-password"
            {...register("password")}
            tabIndex={3}
            type="password"
            id="password"
            className="block px-4 w-full bg-white rounded-md border-solid border-gray-300 border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-9 disabled:opacity-50  dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
          />
          <p className="text-red-500">{errors.password?.message}</p>
        </div>
        <div className="col-span-6 sm:col-span-6">
          <label
            htmlFor="lastName"
            className="block mb-2 text-sm text-gray-900 dark:text-white"
          >
            Confirm password
          </label>
          <input
            autoComplete="new-password"
            {...register("passwordConfirmation")}
            tabIndex={3}
            type="password"
            id="passwordConfirmation"
            className="block px-4 w-full bg-white rounded-md border-solid border-gray-300 border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-9 disabled:opacity-50  dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
          />
          <p className="text-red-500">{errors.passwordConfirmation?.message}</p>
        </div>
      </div>
      <div className="mb-6">
        <button
          type="submit"
          tabIndex={4}
          className="mt-4 px-3 py-4 text-white bg-gray-800 rounded-md focus:bg-gray-600 focus:outline-none disabled:opacity-50 dark:bg-gray-500"
          disabled={isSubmitting || !isDirty || !isValid}
        >
          {isSubmitting ? "Submitting" : "Reset"}
        </button>
      </div>
    </form>
  );
};

export default ResetPasswordForm;
