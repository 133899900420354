import { Link } from 'react-router-dom';
import { UserDropdown } from './UserDropdown';
import { Alerts } from '../elements/Alerts';

export const PageHeader = () => {
  return (
    <div className="flex items-center justify-between mb-2 mt-1">
      <div className="bg-gray-800 px-4 max-w-md">
        <Link to="/">
          <img alt="MASTERyard" className="mx-auto h-10" src="/logo.png"></img>
        </Link>
      </div>
      <div className="ml-auto flex">
        <Alerts />
        <UserDropdown />
      </div>
    </div>
  );
};

export default PageHeader;
