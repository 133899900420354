/**
 * A mutation to request support.
 * Calls the `UserRequestSupport` command.
 */

import { gql, useMutation } from "@apollo/client";

export interface UserRequestSupportInput {
  email: string;
  message: string;
}

export interface UserRequestSupportResponse {
  userRequestSupport: {
    isSuccess: boolean;
    message: string;
  };
}

export const USER_REQUEST_SUPPORT = gql`
  mutation UserRequestSupport($email: String!, $message: String!) {
    userRequestSupport(input: { email: $email, message: $message }) {
      isSuccess
      message
    }
  }
`;

export function useUserRequestSupportMutation() {
  const [mutate, { data, error }] = useMutation<
    UserRequestSupportResponse,
    UserRequestSupportInput
  >(USER_REQUEST_SUPPORT, {
    refetchQueries: [],
  });
  return {
    mutate,
    data,
    error,
  };
}

export default useUserRequestSupportMutation;
