import PageFooter from './PageFooter';
import PageHeader from './PageHeader';

export const PageWrapper = ({ children }: { children: JSX.Element }) => {
  return (
    <div className="h-full items-center min-h-screen ">
      <div className="flex flex-col grow h-full min-h-screen px-4 py-1 mx-auto">
        <PageHeader></PageHeader>
        {children}
        <div className="flex grow"></div>
        <PageFooter></PageFooter>
      </div>
    </div>
  );
};

export default PageWrapper;
