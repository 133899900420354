/**
 * TrialAdd - a command to add a new trial to a site.
 *
 * Inputs:
 *    siteId (string): the site ID where the trial will be conducted
 *    startDate (date): the start date
 *    endDate (date): the end date
 *    penIds (string[]): an array of penIds that will be used in the trial
 */

import { gql, useMutation } from "@apollo/client";
import { Trial } from "../interfaces";

export interface AddTrialInput {
  siteId: string;
  startDate: Date;
  endDate: Date;
  pens: string[];
}

export interface AddTrialResponse {
  siteAddTrial: {
    isSuccess: boolean;
    trial: Trial | null;
    message: string;
  };
}

export const ADD_TRIAL = gql`
  mutation AddTrial(
    $siteId: ID!
    $startDate: Date!
    $endDate: Date!
    $pens: [ID]!
  ) {
    siteAddTrial(
      input: {
        id: $siteId
        startDate: $startDate
        endDate: $endDate
        penIds: $pens
      }
    ) {
      isSuccess
      trial {
        id
        startDate
        endDate
        pens {
          name
        }
      }
      message
    }
  }
`;

export function useAddTrialMutation() {
  const [mutate, { data, error }] = useMutation<
    AddTrialResponse,
    AddTrialInput
  >(ADD_TRIAL, {
    refetchQueries: [],
  });
  return {
    mutate,
    data,
    error,
  };
}

export default useAddTrialMutation;
