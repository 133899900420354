/**
 * A query to get a list of trials.
 */

import { gql, useQuery } from "@apollo/client";
import { Trial } from "../interfaces";

export const GET_TRIAL_BY_ID_WITH_RECORDS = gql`
  query GetTrialById($id: ID!) {
    trialById(id: $id) {
      isSuccess
      message
      trial {
        id
        startDate
        endDate
        site {
          id
          name
        }
        dailyRecords {
          date
          animal {
            tag
          }
          pen {
            name
          }
          feedWeight
          animalWeight
          animalWeightChange
          feedTime
          visits
        }
        pens {
          pen {
            id
            name
          }
        }
      }
    }
  }
`;

interface GetTrialsInput {
  id: string;
}

export interface GetTrialsResponseWithRecords {
  trialById: {
    isSuccess: boolean;
    message: string;
    trial: Trial;
  };
}

export const useQueryGetTrialByIdWithRecords = (id: string) => {
  let trial: Trial = null;
  const { loading, data, error } = useQuery<
    GetTrialsResponseWithRecords,
    GetTrialsInput
  >(GET_TRIAL_BY_ID_WITH_RECORDS, { variables: { id } });

  if (!loading && !error && data && data.trialById.isSuccess) {
    trial = data.trialById.trial;
  }

  return {
    loading,
    error,
    response: {
      isSuccess: data ? data.trialById.isSuccess : false,
      message: data ? data.trialById.message : "",
      trial,
    },
  };
};

export default useQueryGetTrialByIdWithRecords;
