/**
 * The user request Account page.
 */

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useUserRequestSupportMutation } from "../../api/mutations/UserRequestSupport";
import { useAuth } from "../../hooks/useAuth";
import { Modal } from "./Modal";
import { useToasts } from "../../hooks/useToasts";
import { SuccessAlert } from "../app/SuccessAlert";

type UserRequestSupportInput = {
  email: string;
  message: string;
};

interface SupportModalProps {
  onSuccess(): void;
  onCancel(): void;
}

export const SupportModal = ({ onSuccess, onCancel }: SupportModalProps) => {
  const { mutate: userRequestSupportMutation } =
    useUserRequestSupportMutation();

  const { currentUser } = useAuth();
  const { newToast } = useToasts();

  // Validation schema for the form inputs
  const schema = Yup.object().shape({
    email: Yup.string(),
    //   .email("Must be a valid email address")
    //   .required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  // Use react-form-hook with yup for validation
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting, isValid },
  } = useForm<UserRequestSupportInput>({
    mode: "all",
    resolver: yupResolver(schema) as any,
  });

  // State of the form
  const [error, setError] = useState<string | null>(null);

  const onSubmit = async (input: UserRequestSupportInput) => {
    const { message } = input;
    setError(null);
    try {
      const { data } = await userRequestSupportMutation({
        variables: {
          email: currentUser.email,
          message,
        },
      });
      if (!data.userRequestSupport.isSuccess) {
        setError(data.userRequestSupport.message);
        setFocus("email");
      }
      newToast(<SuccessAlert message="Support request submitted" />);
      onSuccess();
    } catch (err: any) {
      if (err.message === "NetworkError when attempting to fetch resource.") {
        setError("Error: server is currently unavailable.");
      } else {
        setError(`${err.message}`);
        setFocus("email");
      }
    }
  };

  return (
    <Modal title="Request Support" onClose={onCancel}>
      <div v-if={error} className="text-red-500 mb-4">
        {error}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
        <div className="mb-6">
          <label
            htmlFor="email"
            className="block mb-2 text-sm text-gray-900 dark:text-white"
          >
            Email Address
          </label>
          <input
            autoComplete="off"
            {...register("email")}
            tabIndex={1}
            type="email"
            id="email"
            value={currentUser.email}
            placeholder="you@company.com"
            className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-gray-400 disabled:opacity-50 dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
            disabled={true}
          />
          <p className="text-red-500">{errors.email?.message}</p>
        </div>

        <div className="mb-6">
          <label
            htmlFor="message"
            className="block mb-2 text-sm text-gray-900 dark:text-white"
          >
            Message
          </label>
          <textarea
            autoFocus
            autoComplete="off"
            rows={4}
            maxLength={500}
            {...register("message")}
            tabIndex={1}
            id="message"
            placeholder="Describe your problem"
            className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-gray-400 dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
          />
          <p className="text-red-500">{errors.message?.message}</p>
        </div>

        <div className="mb-6">
          <button
            type="submit"
            tabIndex={4}
            className="w-full mt-4 px-3 py-4 text-white bg-gray-800 rounded-md focus:bg-gray-600 focus:outline-none disabled:opacity-50 dark:bg-gray-500"
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? "Submitting" : "Submit"}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default SupportModal;
