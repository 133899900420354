export const Version = () => {
  const ENV = process.env.REACT_APP_ENVIRONMENT;
  const COMMIT = process.env.REACT_APP_COMMIT;
  const VER = process.env.REACT_APP_VERSION;
  const VERSION = `${ENV}-${VER}-${COMMIT}`;

  return <span>{VERSION}</span>;
};

export default Version;
