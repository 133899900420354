import moment from "moment-timezone";
import { Link, useParams } from "react-router-dom";
import { AnimalDailyData, Pen, Trial } from "../api/interfaces";
import { useQueryGetTrialByIdWithRecords } from "../api";
import {
  GrowthChart,
  GrowthDataSeries,
} from "../components/charts/GrowthChart";
import {
  IntakeChart,
  IntakeDataSeries,
} from "../components/charts/IntakeChart";
import { TrialSummaryDataTable } from "../components/elements/TrialSummaryData";
import {
  calculateGrowthData,
  calculateIntakeData,
  calculateSummaryData,
} from "../modules/data-processing";
import { TrialStatus } from "../components/elements/TrialStatus";
import { useEffect, useState } from "react";
import { FilterIcon } from "../components/icons/FilterIcon";
import { TrialDataFilter } from "../components/elements/TrialDataFilter";
import { ErrorComponent } from "../components/elements/ErrorComponent";
import { LoadingComponent } from "../components/elements/LoadingComponent";
import PageWrapper from "../components/app/PageWrapper";

export const TrialDashboardPage = () => {
  const params = useParams();
  const [data, setData] = useState<AnimalDailyData[]>([]);
  const [intakeData, setIntakeData] = useState<IntakeDataSeries>();
  const [growthData, setGrowthData] = useState<GrowthDataSeries>();
  const [summaryData, setSummaryData] = useState<any>();

  const {
    loading: trialLoading,
    error: trialError,
    response: trialResponse,
  } = useQueryGetTrialByIdWithRecords(params.trialId);

  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    if (!trialLoading && trialResponse.trial) {
      const records: AnimalDailyData[] = trialResponse.trial.dailyRecords;
      // for (const pen of trialResponse.trial.pens) {
      //   records.concat(pen.records);
      // }
      setData(
        records.map((r) => {
          return { ...r, date: moment(r.date).toDate() };
        })
      );
    }
  }, [trialLoading, trialResponse.trial]);

  useEffect(() => {
    if (data) {
      setIntakeData(calculateIntakeData(data.reverse()));
      setGrowthData(calculateGrowthData(data));
      setSummaryData(calculateSummaryData(data.reverse()));
    }
  }, [data]);

  if (trialLoading) {
    return <LoadingComponent></LoadingComponent>;
  }

  if (trialError) {
    return <ErrorComponent error={trialError} />;
  }

  const trial = trialResponse.trial;
  const title = (trial: Trial) => {
    return `Trial ${moment(trial.startDate).format("DD-MMM-YYYY")} to
    ${moment(trial.endDate).format("DD-MMM-YYYY")}`;
  };

  const onApplyFilters = (pens: Pen[], startDate: Date, endDate: Date) => {
    const theData = data
      .filter((d) => pens.map((p) => p.name).includes(d.pen.name))
      .filter((d) => {
        return d.date >= startDate && d.date <= endDate;
      });
    setData(theData);
    setShowFilters(false);
  };

  const onCancelFilterModal = () => {
    setShowFilters(false);
  };

  return (
    <PageWrapper>
      <div className="flex flex-col">
        <div className="flex">
          <div className="flex">
            <div className=" flex items-center justify-between pb-3">
              <div>
                <h2 className="text-gray-600">
                  <Link className="cursor-pointer font-semibold" to="/sites">
                    Sites:
                  </Link>{" "}
                  <Link
                    className="cursor-pointer font-semibold"
                    to={`/sites/${trial.site.id}`}
                  >
                    {trial.site.name}:
                  </Link>{" "}
                  {title(trial)}
                </h2>
              </div>
            </div>
          </div>
          <div className="ml-auto flex mr-2">
            <div className="ml-auto flex">
              <TrialStatus trial={trial}></TrialStatus>
            </div>
          </div>
        </div>
        <div className="mb-0 mt-1 ml-auto mr-2">
          {showFilters ? (
            <TrialDataFilter
              trial={trial}
              onApply={onApplyFilters}
              onCancel={onCancelFilterModal}
            ></TrialDataFilter>
          ) : data.length ? (
            <button onClick={() => setShowFilters(true)}>
              {" "}
              <FilterIcon></FilterIcon>
              <span className="ml-2 text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500  cursor-pointer">
                Filter Data
              </span>
            </button>
          ) : (
            ""
          )}
        </div>

        {data.length ? (
          <>
            <TrialSummaryDataTable
              data={{
                meanFeedConsumed: summaryData.feedConsumedPerDayPerAnimal,
                meanLiveweight: summaryData.liveweight,
                meanGrowthRate: summaryData.growthPerDayPerAnimal,
                totalRecords: summaryData.visits,
                recordsPerDay: summaryData.visitsPerDay,
                animalsOnFeed: summaryData.animals,
              }}
            ></TrialSummaryDataTable>

            <div className="grid grid-cols-2 gap-8 mt-0 flex-1 px-4 py-4">
              <div className="col-span-2 lg:col-span-1 h-[400px] min-h-[400px]">
                <h2 className="font-bold font-md mb-6 text-gray-900 dark:text-white">
                  Daily Feed Intake
                </h2>
                <IntakeChart series={intakeData} />
              </div>
              <div className="col-span-2 lg:col-span-1 h-[400px] min-h-[400px]">
                <h2 className="font-bold font-md mb-6 text-gray-900 dark:text-white">
                  Daily Growth
                </h2>
                <GrowthChart series={growthData} />
              </div>
            </div>
          </>
        ) : (
          <div className="text-gray-900 dark:text-white">No data</div>
        )}
      </div>
    </PageWrapper>
  );
};

export default TrialDashboardPage;
