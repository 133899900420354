/**
 * A mutation to request a new user account.
 * Calls the `UserRequestAccount` command.
 */

import { gql, useMutation } from "@apollo/client";

export interface UserRequestAccountInput {
  email: string;
  firstName: string;
  lastName: string;
  reason: string;
}

export interface UserRequestAccountResponse {
  userRequestAccount: {
    isSuccess: boolean;
    message: string;
  };
}

export const USER_REQUEST_ACCOUNT = gql`
  mutation UserRequestAccount(
    $email: String!
    $firstName: String!
    $lastName: String!
    $reason: String!
  ) {
    userRequestAccount(
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        reason: $reason
      }
    ) {
      isSuccess
      message
    }
  }
`;

export function useUserRequestAccountMutation() {
  const [mutate, { data, error }] = useMutation<
    UserRequestAccountResponse,
    UserRequestAccountInput
  >(USER_REQUEST_ACCOUNT, {
    refetchQueries: [],
  });
  return {
    mutate,
    data,
    error,
  };
}

export default useUserRequestAccountMutation;
