import { Feeder } from '../../api/interfaces';

interface FeederPlatformProps {
  feeder: Feeder;
}

export const FeederPlatform = ({ feeder }: FeederPlatformProps) => {
  if (!feeder.controller) return <></>;

  const weight = (feeder.controller.platformWeight / 1000).toFixed(3);

  switch (feeder.controller.platformState) {
    case 'STABLE':
      return (
        <div className="text-sm text-center text-purple-500 dark:text-white">
          <span className="border border-purple-500 px-2">{weight}</span>
          <div>Occupied</div>
        </div>
      );
    case 'EMPTY':
      return (
        <div className="text-sm text-center text-green-500 dark:text-white">
          <span className="border border-green-500 px-2">{weight}</span>
          <div>Clear</div>
        </div>
      );
    default:
      return (
        <div className="text-sm text-center text-red-500 dark:text-white">
          <span className="border border-red-500 px-2">{weight}</span>
          <div>Error</div>
        </div>
      );
  }
};

export default FeederPlatform;
