/**
 * A query to get a list of notifications.
 */

import { gql, useQuery } from '@apollo/client';
import { Notification } from '../interfaces';

export const GET_NOTIFICATIONS = gql`
  query GetNotifications {
    notifications @client {
      isSuccess
      message
      notifications {
        id
        timestamp
        message
        level
      }
    }
  }
`;

interface GetNotificationsInput {}

export interface GetNotificationsResponse {
  notifications: {
    isSuccess: boolean;
    message: string;
    notifications: Notification[];
    meta: any;
  };
}

export const useQueryGetNotifications = () => {
  let notifications: Notification[] = [];
  const { loading, data, error } = useQuery<
    GetNotificationsResponse,
    GetNotificationsInput
  >(GET_NOTIFICATIONS);

  if (!loading && !error && data && data.notifications.isSuccess) {
    notifications = data.notifications.notifications;
  }

  return {
    loading,
    error,
    response: {
      isSuccess: data ? data.notifications.isSuccess : false,
      message: data ? data.notifications.message : '',
      notifications,
    },
  };
};

export default useQueryGetNotifications;
