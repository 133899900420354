import moment from 'moment-timezone';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import { PageWrapper } from '../components/app/PageWrapper';
import { Feeder, useQueryGetSiteById } from '../api';
import { FeederHealthStatus } from '../components/elements/FeederHealthStatus';
import { FeederCalibrationModal } from '../components/elements/FeederCalibrationModal';
import { LoadingComponent } from '../components/elements/LoadingComponent';
import { ErrorComponent } from '../components/elements/ErrorComponent';
import useQueryGetFeedersBySiteId from '../api/queries/GetFeedersBySiteId';
import FeederOnlineStatus from '../components/elements/FeederOnlineStatus';
import FeederState from '../components/elements/FeederState';
import FeederProximity from '../components/elements/FeederProximity';
import FeederTub from '../components/elements/FeederTub';
import FeederPlatform from '../components/elements/FeederPlatform';
import FeederRFID from '../components/elements/FeederRFID';
import { FeederCard } from '../components/elements/FeederCard';
import { FeederList } from '../components/lists/FeederList';

export const FeedersPage = () => {
  const params = useParams();
  const siteId = params.siteId;
  const navigate = useNavigate();
  const { loading, error, response } = useQueryGetSiteById(siteId);
  const {
    loading: loadingFeeders,
    error: errorFeeders,
    response: responseFeeders,
  } = useQueryGetFeedersBySiteId(siteId);

  if (loading || loadingFeeders) {
    return <LoadingComponent></LoadingComponent>;
  }

  if (error || !response.isSuccess) {
    return (
      <ErrorComponent error={error} message={response.message}></ErrorComponent>
    );
  }

  if (errorFeeders || !responseFeeders.isSuccess) {
    return (
      <ErrorComponent
        error={errorFeeders}
        message={responseFeeders.message}
      ></ErrorComponent>
    );
  }

  const site = response.site;

  const onClickTrials = () => {
    navigate(`/sites/${siteId}/trials`);
  };

  return (
    <PageWrapper>
      <div className="ml-4">
        <div className="flex">
          <div className=" flex items-center justify-between pb-3">
            <div>
              <h2 className="text-gray-600">
                <Link className="cursor-pointer font-semibold" to="/sites">
                  Sites:
                </Link>{' '}
                <Link
                  className="cursor-pointer font-semibold"
                  to={`/sites/${site.id}`}
                >
                  {site.name}:
                </Link>{' '}
                Manage Feeders
              </h2>
            </div>
          </div>
        </div>

        <div>
          <FeederList feeders={responseFeeders.feeders}></FeederList>
        </div>
      </div>
    </PageWrapper>
  );
};

export default FeedersPage;
