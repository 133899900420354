/**
 * A mutation to login.
 * Calls the `Login` command.
 */

import { gql, useMutation } from "@apollo/client";
import { User } from "../interfaces";

export interface LoginInput {
  email: string;
  password: string;
}

export interface LoginResponse {
  userLogin: {
    isSuccess: boolean;
    message: string;
    user: User;
    token: string;
  };
}

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    userLogin(input: { email: $email, password: $password }) {
      isSuccess
      message
      token
      user {
        email
        firstName
        lastName
        passwordResetRequired
      }
    }
  }
`;

export function useLoginMutation() {
  const [mutate, { data, error }] = useMutation<LoginResponse, LoginInput>(
    LOGIN,
    {
      refetchQueries: [],
    }
  );
  return {
    mutate,
    data,
    error,
  };
}

export default useLoginMutation;
