/**
 * The login page.
 */

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

interface Props {
  // Show a link for retrieving a lost password (default: false)
  showForgotPasswordLink?: boolean;
  // Show a link for account sign up (default: false)
  showSignUpLink?: boolean;
}

type LoginInput = {
  email: string;
  password: string;
};

export const LoginPage = ({
  showForgotPasswordLink = false,
  showSignUpLink = false,
}: Props) => {
  const navigate = useNavigate();
  const { isAuthenticated, login } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      return navigate("/");
    }
  }, [isAuthenticated, navigate]);

  // Validation schema for the form inputs
  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  // Use react-form-hook with yup for validation
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<LoginInput>({
    mode: "onSubmit",
    resolver: yupResolver(schema) as any,
  });

  // State of the form
  const [error, setError] = useState<string | null>(null);

  const onSubmit = async (input: LoginInput) => {
    const { email, password } = input;
    setError(null);
    try {
      const user = await login(email, password);
      if (user) {
        navigate(`/`);
      }
    } catch (err: any) {
      if (err.message === "NetworkError when attempting to fetch resource.") {
        setError("Error: server is currently unavailable.");
      } else {
        setError(`${err.message}`);
        setFocus("email");
      }
    }
  };

  return (
    <div className="flex items-center w-full min-h-screen bg-white dark:bg-gray-800">
      <div className="container mx-auto">
        <div className="max-w-md mx-auto my-2 border dark:border-gray-600 pb-4">
          <div className="bg-gray-800 mx-auto max-w-md">
            <img alt="MASTERyard" className="mx-auto h-14" src="logo.png"></img>
          </div>
          <div className="text-center">
            {error ? <p className="text-red-500">{error}</p> : ""}
          </div>
          <div className="m-7">
            <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm text-gray-900 dark:text-white"
                >
                  Email Address
                </label>
                <input
                  autoFocus
                  autoComplete="off"
                  {...register("email")}
                  tabIndex={1}
                  type="email"
                  id="email"
                  placeholder="you@company.com"
                  className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 dark:border-gray-700 rounded-md focus:outline-none focus:border-gray-400 dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
                />
                <p className="text-red-500">{errors.email?.message}</p>
              </div>
              <div className="mb-6">
                <div className="flex justify-between mb-2">
                  <label
                    htmlFor="password"
                    className="text-sm text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  {showForgotPasswordLink ? (
                    <Link
                      to="/forgotten-password"
                      className="text-sm font-medium text-blue-500 dark:text-blue-500 focus:outline-none focus:text-blue-500 hover:text-blue-800"
                    >
                      Forgot password?
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
                <input
                  type="password"
                  id="password"
                  autoComplete="on"
                  tabIndex={2}
                  {...register("password")}
                  placeholder="Your Password"
                  className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-gray-400 dark:focus:border-blue-200 dark:bg-gray-800 dark:text-whit"
                />
                <p className="text-red-500">{errors.password?.message}</p>
              </div>
              <div className="mb-6">
                <button
                  type="submit"
                  tabIndex={3}
                  className="w-full px-3 py-4 text-white bg-gray-800 dark:bg-gray-500 rounded-md focus:bg-gray-600 focus:outline-none disabled:opacity-50"
                  disabled={!isValid || !isDirty || isSubmitting}
                >
                  {isSubmitting ? "Signing in" : "Sign in"}
                </button>
              </div>
              {showSignUpLink ? (
                <p className="text-sm text-center text-gray-400">
                  <Link
                    to="/sign-up"
                    className="text-sm font-medium text-blue-500 dark:text-blue-500 focus:outline-none focus:text-blue-500 hover:text-blue-800"
                  >
                    Request an account
                  </Link>
                </p>
              ) : (
                ""
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
