import { ApolloError } from "@apollo/client";
import PageWrapper from "../app/PageWrapper";

interface ErrorComponentProps {
  error?: ApolloError;
  message?: string;
}

export const ErrorComponent = ({ error, message }: ErrorComponentProps) => {
  const errorMsg = (err: ApolloError) => {
    const { graphQLErrors } = err;
    if (graphQLErrors) {
      for (const { extensions } of graphQLErrors) {
        if (extensions && extensions.code === "FORBIDDEN") {
          return (
            <span>You do not have permission to access this resource</span>
          );
        }
      }
    }
    return JSON.stringify(err);
  };

  if (!error && !message) return <></>;

  return (
    <PageWrapper>
      <div className="mx-4 my-4">
        <div className="flex flex-col text-md text-red-500 ">
          <h1 className="text-xl font-bold">Error</h1>
          <div>{error ? errorMsg(error) : message}</div>
          <div className="mt-4 text-gray-900 dark:text-white">
            Please contact{" "}
            <a href="mailto://support@masteryard.com.au">
              support@masteryard.com.au
            </a>
            .
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
