import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const SitePage = () => {
  const params = useParams();
  const siteId = params.siteId;

  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/sites/${siteId}/trials`);
  }, [navigate, siteId]);

  return <> </>;
};
