/**
 * A mutation to reset a user password.
 * Calls the `UserResetPassword` command.
 */

import { gql, useMutation } from "@apollo/client";

export interface UserResetPasswordInput {
  email: string;
}

export interface UserResetPasswordResponse {
  userResetPassword: {
    isSuccess: boolean;
    message: string;
  };
}

export const USER_RESET_PASSWORD = gql`
  mutation UserResetPassword($email: String!) {
    userResetPassword(input: { email: $email }) {
      isSuccess
      message
    }
  }
`;

export function useUserResetPasswordMutation() {
  const [mutate, { data, error }] = useMutation<
    UserResetPasswordResponse,
    UserResetPasswordInput
  >(USER_RESET_PASSWORD, {
    refetchQueries: [],
  });

  return {
    mutate,
    data,
    error,
  };
}

export default useUserResetPasswordMutation;
