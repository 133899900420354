import moment from "moment-timezone";
import { Trial } from "../../api/interfaces";

interface TrialStatusProps {
  trial: Trial;
}

export const TrialStatus = (props: TrialStatusProps) => {
  const trial = props.trial;
  let text = "";
  let colour = "";
  if (
    moment().isSameOrAfter(trial.startDate, "day") &&
    moment().isSameOrBefore(trial.endDate, "day")
  ) {
    text = "In Progress";
    colour = "text-green-500 dark:text-green-500";
  }
  if (moment().isBefore(trial.startDate, "day")) {
    text = "Scheduled";
    colour = "text-pink-500 dark:text-pink-500";
  }
  if (moment().isAfter(trial.endDate, "day")) {
    text = "Ended";
    colour = "text-orange-500 dark:text-orange-500";
  }

  return (
    <div
      className={`text-sm inline-flex items-center text-base font-semibold ${colour}`}
    >
      {text}
    </div>
  );
};

export default TrialStatus;
