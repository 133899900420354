export interface OnlineBallProps {
  isOnline: boolean;
}

export const OnlineBall = ({ isOnline }: OnlineBallProps) => {
  if (isOnline) {
    return (
      <div
        className={`inline-block h-2 w-2 bg-green-500 rounded-full mr-2`}
      ></div>
    );
  } else {
    return (
      <div
        className={`inline-block h-2 w-2 bg-red-500 rounded-full mr-2`}
      ></div>
    );
  }
};
