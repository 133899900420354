import { createContext, useContext, useState } from "react";

interface IToastContext {
  toasts: any[];
  setToasts: (toast: any) => void;
  newToast: (component: any, timeout?: number) => void;
  closeToast: (id: number) => void;
}

const ToastContext = createContext<IToastContext | undefined>(undefined);

export const useToasts = () => useContext(ToastContext);

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState([]);

  const newToast = (component: any, timeout = 5000) => {
    if (toasts.length > 5) {
      throw new Error("too many toasts");
    }
    const id = Date.now();
    setToasts((toasts) => [...toasts, { id, component }]);

    setTimeout(() => closeToast(id), timeout);
  };

  const closeToast = (id: number) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ toasts, setToasts, newToast, closeToast }}>
      {children}
      <div className="space-y-2 absolute top-2 inset-x-0 max-w-max mx-auto z-50">
        {toasts.map(({ id, component }) => (
          <div key={id} className="relative">
            {component}
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
};
