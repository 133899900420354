import { Link, useNavigate } from "react-router-dom";
import { useQueryGetSites, Site } from "../api";
import { PageWrapper } from "../components/app/PageWrapper";
import { useEffect } from "react";
import { ErrorComponent } from "../components/elements/ErrorComponent";
import { LoadingComponent } from "../components/elements/LoadingComponent";
import { SiteList } from "../components/lists/SitesList";

export const SitesPage = () => {
  const navigate = useNavigate();
  const { loading, error, response } = useQueryGetSites();

  useEffect(() => {
    if (response.sites.length === 1) {
      navigate(`/sites/${response.sites[0].id}`);
    }
  }, [response.sites, navigate]);

  const sites = response.sites;

  if (loading) {
    return <LoadingComponent></LoadingComponent>;
  }

  if (error || !response.isSuccess) {
    return (
      <ErrorComponent error={error} message={response.message}></ErrorComponent>
    );
  }

  return (
    <PageWrapper>
      <div>
        <div className="flex">
          <div className=" flex items-center justify-between pb-3">
            <div>
              <h2 className="text-gray-600">
                <Link className="cursor-pointer font-semibold" to="/sites">
                  Sites
                </Link>
              </h2>
            </div>
          </div>
        </div>
        <SiteList sites={sites}></SiteList>
      </div>
    </PageWrapper>
  );
};

export default SitesPage;
