import moment from 'moment-timezone';
import { Feeder, Site } from '../../api';
import FeederState from '../elements/FeederState';
import FeederTub from '../elements/FeederTub';
import FeederPlatform from '../elements/FeederPlatform';
import FeederProximity from '../elements/FeederProximity';
import FeederRFID from '../elements/FeederRFID';
import FeederOnlineStatus from '../elements/FeederOnlineStatus';
import FeederFirmwareVersion from '../elements/FeederFirmwareVersion';
import FeederIPAddress from '../elements/FeederIPAddress';
import { useEffect, useMemo, useState } from 'react';
import { FeederCalibrationModal } from '../elements/FeederCalibrationModal';
import { OnlineBall } from '../elements/OnlineBall';
import { ColumnProps, DataTable } from './DataTable';
import CrossIcon from '../icons/CrossIcon';
import FilterIcon from '../icons/FilterIcon';

const DEFAULT_DISPLAY_COLUMNS = [
  'name',
  'status',
  'ipAddress',
  'firmware',
  'updated',
  'connection',
  'tub',
  'platform',
  'proximity',
  'rfid',
  'actions',
];

export interface FeederListProps {
  feeders: Feeder[];
}

export const FeederList = ({ feeders }: FeederListProps) => {
  const [showCalibrateModel, setShowCalibrateModal] = useState(false);
  const [selectedFeeder, setSelectedFeeder] = useState<Feeder>(null);
  const [displayColumns, setDisplayColumns] = useState<Array<string>>(
    DEFAULT_DISPLAY_COLUMNS
  );
  const [showFilters, setShowFilters] = useState(false);
  const [inputText, setInputText] = useState('');
  const [filteredFeeders, setFilteredFeeders] = useState([]);
  const [showOnline, setShowOnline] = useState('all');
  const [showHealthy, setShowHealthy] = useState('all');

  const columns = useMemo<Array<ColumnProps<Feeder>>>(
    () => [
      {
        key: 'name',
        title: 'Name',

        render: (_, feeder) => {
          return (
            <div>
              <div className="font-semibold">
                <OnlineBall isOnline={feeder.controller?.isOnline}></OnlineBall>
                {feeder.name}
              </div>
              <div>{feeder.controller?.controllerId}</div>
            </div>
          );
        },
      },
      {
        key: 'status',
        title: 'Status',
        render: (_, feeder) => {
          return (
            <div>
              <FeederState feeder={feeder}></FeederState>
              {!isHealthy(feeder) ? (
                <div className="text-red-500">Unhealthy</div>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
      {
        key: 'ipAddress',
        title: 'IP Address',
        render: (_, feeder) => {
          return (
            <div>
              {feeder.controller?.ipAddress === 'UNKNOWN' ? (
                <></>
              ) : (
                feeder.controller?.ipAddress
              )}
            </div>
          );
        },
      },
      {
        key: 'firmware',
        title: 'Firmware',
        render: (_, feeder) => {
          return (
            <div>
              {' '}
              {feeder.controller?.firmwareVersion === 'UNKNOWN' ? (
                <></>
              ) : (
                feeder.controller?.firmwareVersion
              )}
            </div>
          );
        },
      },
      {
        key: 'updated',
        title: 'Updated',
        render: (_, feeder) => {
          return (
            <>
              {feeder.controller?.statusUpdateTime ? (
                <div className="whitespace-no-wrap">
                  {moment(
                    new Date(feeder.controller?.statusUpdateTime)
                  ).fromNow()}
                </div>
              ) : (
                <></>
              )}
            </>
          );
        },
      },
      {
        key: 'connection',
        title: 'Connection Event',
        render: (_, feeder) => {
          return (
            <div>
              {feeder.controller?.connectionEventTime ? (
                <>
                  <div className="whitespace-no-wrap">
                    {feeder.controller?.isOnline ? 'Connected' : 'Disconnected'}
                  </div>
                  <div className="whitespace-no-wrap">
                    {moment(
                      new Date(feeder.controller?.connectionEventTime)
                    ).fromNow()}
                  </div>{' '}
                </>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
      {
        key: 'tub',
        title: 'Tub (kg)',
        render: (_, feeder) => {
          return (
            <div className="whitespace-no-wrap">
              <FeederTub feeder={feeder}></FeederTub>
            </div>
          );
        },
      },
      {
        key: 'platform',
        title: 'Platform (kg)',
        render: (_, feeder) => {
          return (
            <div className="whitespace-no-wrap">
              <FeederPlatform feeder={feeder}></FeederPlatform>
            </div>
          );
        },
      },
      {
        key: 'proximity',
        title: 'Proximity (cm)',
        render: (_, feeder) => {
          return (
            <div className="whitespace-no-wrap">
              <FeederProximity feeder={feeder}></FeederProximity>
            </div>
          );
        },
      },
      {
        key: 'rfid',
        title: 'RFID',
        render: (_, feeder) => {
          return (
            <div className="whitespace-no-wrap">
              <FeederRFID feeder={feeder}></FeederRFID>
            </div>
          );
        },
      },
      {
        key: 'actions',
        title: 'Actions',
        render: (_, feeder) => {
          return (
            <div>
              <span
                className="bg-blue-500 text-white px-2 py-2 text-sm font-medium text-blue-600 hover:bg-blue-800 cursor-pointer"
                onClick={() => {
                  setSelectedFeeder(feeder);
                  setShowCalibrateModal(true);
                }}
              >
                Calibrate
              </span>
              <span
                className="bg-blue-500 text-white px-2 py-2 ml-2 text-sm font-medium text-blue-600 hover:bg-blue-800 cursor-pointer"
                onClick={() => {}}
              >
                Disable
              </span>
              <span
                className="bg-blue-500 text-white px-2 py-2 ml-2 text-sm font-medium text-blue-600 hover:bg-blue-800 cursor-pointer"
                onClick={() => {}}
              >
                Reboot
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const [displayedColumns, setDisplayedColumns] = useState(columns);

  const isHealthy = (feeder: Feeder) => {
    if (!feeder.controller?.isOnline) return false;
    if (feeder.controller?.state === 'UNKNOWN') return false;

    const updated = feeder.controller?.statusUpdateTime;

    if (!updated) return false;
    if (moment().diff(moment(updated), 'days') > 1) return false;

    if (feeder.controller.proximityState.includes('ERROR')) return false;
    if (feeder.controller.tubState.includes('ERROR')) return false;
    if (feeder.controller.platformState.includes('ERROR')) return false;
    if (feeder.controller.rfidState.includes('ERROR')) return false;
    if (feeder.controller.state.includes('ERROR')) return false;
    if (
      feeder.controller.state.replace('State', '').toUpperCase() === 'IDLE' &&
      feeder.controller.platformState !== 'EMPTY'
    )
      return false;

    return true;
  };

  useEffect(() => {
    setFilteredFeeders(
      feeders
        .filter((feeder) => {
          //if no input the return the original
          if (inputText === '') {
            return feeder;
          }
          //return the item which contains the user input
          else {
            return feeder.name.toLowerCase().includes(inputText);
          }
        })
        .filter((feeder) => {
          if (showOnline === 'all') return true;
          if (showOnline === 'yes' && feeder.controller?.isOnline) return true;
          if (showOnline === 'no' && !feeder.controller?.isOnline) return true;
          return false;
        })
        .filter((feeder) => {
          if (showHealthy === 'all') return true;
          if (showHealthy === 'yes' && isHealthy(feeder)) return true;
          if (showHealthy === 'no' && !isHealthy(feeder)) return true;
          return false;
        })
    );
  }, [inputText, feeders, showOnline, showHealthy]);

  useEffect(() => {
    if (selectedFeeder) {
      setSelectedFeeder({
        ...feeders.find((f) => f.id === selectedFeeder.id),
      });
    }
  }, [feeders, selectedFeeder]);

  useEffect(() => {
    setDisplayedColumns(columns.filter((c) => displayColumns.includes(c.key)));
  }, [displayColumns, columns]);

  let inputHandler = (e: any) => {
    //convert input text to lower case
    var searchText = e.target.value.toLowerCase();
    setInputText(searchText);
  };

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex mb-5 text-sm">
          <div className="flex bg-gray-100 p-2 rounded-md w-1/4 dark:bg-gray-800 dark:text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-400 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
            <input
              className="bg-gray-100 outline-none ml-1 block dark:bg-gray-800 dark:text-white"
              type="text"
              onChange={inputHandler}
              name=""
              id=""
              placeholder="Search"
            />
          </div>

          <div className="ml-auto">
            <div
              className="inline-flex rounded-md text-xs shadow-sm mr-4"
              role="group"
            >
              <button
                type="button"
                disabled={showHealthy === 'yes'}
                className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 disabled:bg-gray-300 disabled:dark:bg-gray-300"
                onClick={() => {
                  setShowHealthy('yes');
                }}
              >
                Healthy
              </button>
              <button
                type="button"
                disabled={showHealthy === 'no'}
                className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 disabled:bg-gray-300 disabled:dark:bg-gray-300"
                onClick={() => {
                  setShowHealthy('no');
                }}
              >
                Unhealthy
              </button>
              <button
                type="button"
                disabled={showHealthy === 'all'}
                className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 disabled:bg-gray-300 disabled:dark:bg-gray-300"
                onClick={() => {
                  setShowHealthy('all');
                }}
              >
                All
              </button>
            </div>
            <div
              className="inline-flex rounded-md text-xs shadow-sm mr-4"
              role="group"
            >
              <button
                type="button"
                disabled={showOnline === 'yes'}
                className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 disabled:bg-gray-300 disabled:dark:bg-gray-300"
                onClick={() => {
                  setShowOnline('yes');
                }}
              >
                Online
              </button>
              <button
                type="button"
                disabled={showOnline === 'no'}
                className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 disabled:bg-gray-300 disabled:dark:bg-gray-300"
                onClick={() => {
                  setShowOnline('no');
                }}
              >
                Offline
              </button>
              <button
                type="button"
                disabled={showOnline === 'all'}
                className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 disabled:bg-gray-300 disabled:dark:bg-gray-300"
                onClick={() => {
                  setShowOnline('all');
                }}
              >
                All
              </button>
            </div>
            <div className="inline-flex" onClick={() => setShowFilters(true)}>
              <span className="text-gray-400">
                <FilterIcon></FilterIcon>
              </span>
            </div>
          </div>
          {showFilters ? (
            <nav className="fixed z-10 right-0 h-screen shadow bg-white w-72 text-gray-700 p-8">
              <div className="flex items-center mb-4">
                <h1 className="font-bold">Display Options</h1>
                <div className="ml-auto">
                  <span
                    className="p-4 cursor-pointer"
                    onClick={() => setShowFilters(false)}
                  >
                    <CrossIcon></CrossIcon>
                  </span>
                </div>
              </div>
              <div className="text-sm">
                {columns.map((c) => (
                  <div
                    className="p-1"
                    onClick={() => {
                      if (displayColumns.includes(c.key)) {
                        displayColumns.splice(displayColumns.indexOf(c.key), 1);
                        setDisplayColumns([...displayColumns]);
                      } else {
                        displayColumns.push(c.key);
                        setDisplayColumns([...displayColumns]);
                      }
                    }}
                  >
                    <input
                      type="checkbox"
                      className="mr-2"
                      defaultChecked={displayColumns.includes(c.key)}
                    />
                    {c.title}
                  </div>
                ))}
              </div>
            </nav>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="flex bg-white rounded-md w-full">
        <DataTable data={filteredFeeders} columns={displayedColumns} />

        {showCalibrateModel ? (
          <FeederCalibrationModal
            onCancel={() => setShowCalibrateModal(false)}
            onSuccess={() => setShowCalibrateModal(false)}
            feeder={selectedFeeder}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
