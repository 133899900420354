import { Pen } from "../../api/interfaces";
import { MultiSelectDropdown } from "./MultiSelect";

export interface TrialPenFilterProps {
  pens: Pen[];
  onSelect(selectedPens: Pen[]): void;
}

export const TrialPenFilter = ({ pens, onSelect }: TrialPenFilterProps) => {
  const onChange = (selected: string[]) => {
    const selectedPens = pens.filter((p) => selected.includes(p.name));
    onSelect(selectedPens);
  };
  return (
    <>
      <label
        htmlFor="pens"
        className="block mb-2 text-sm text-gray-900 dark:text-white"
      >
        Pens
      </label>

      <MultiSelectDropdown
        formFieldName="pens"
        options={pens.map((p) => p.name)}
        onChange={onChange}
      ></MultiSelectDropdown>
    </>
  );
};
