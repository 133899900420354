/**
 * A mutation to userEdit.
 * Calls the `UserEdit` command.
 */

import { gql, useMutation } from "@apollo/client";
import { User } from "../interfaces";

export interface UserEditInput {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface UserEditResponse {
  userEdit: {
    isSuccess: boolean;
    message: string;
    user: User;
  };
}

export const USEREDIT = gql`
  mutation UserEdit(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    userEdit(
      input: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      isSuccess
      message
      user {
        email
        firstName
        lastName
        isApproved
        passwordResetRequired
      }
    }
  }
`;

export function useUserEditMutation() {
  const [mutate, { data, error }] = useMutation<
    UserEditResponse,
    UserEditInput
  >(USEREDIT, {
    refetchQueries: [],
  });
  return {
    mutate,
    data,
    error,
  };
}

export default useUserEditMutation;
