import { Feeder } from '../../api/interfaces';

interface FeederStateProps {
  feeder: Feeder;
}

export const FeederState = ({ feeder }: FeederStateProps) => {
  let colour = 'bg-blue-500';

  let state = feeder.controller?.state
    .replaceAll('State', '')
    .replaceAll('_', ' ')
    .toUpperCase();

  if (!feeder.controller) {
    state = 'UNKNOWN';
  }

  if (!feeder.controller?.isOnline) {
    state = 'DISCONNECTED';
  }

  switch (state) {
    case 'IDLE':
      return (
        <div className="border-green-500 text-green-500 dark:text-white border text-sm rounded-sm px-1 inline-flex items-center text-base">
          {state}
        </div>
      );
    case 'CALIBRATING':
      return (
        <div className="border-pink-500 text-pink-500 dark:text-white border text-sm rounded-sm px-1 inline-flex items-center text-base">
          {state}
        </div>
      );
    case 'SCANNING TAG':
      return (
        <div className="border border-purple-500 text-purple-500 dark:text-white text-sm rounded-sm px-1 inline-flex items-center text-base">
          {state}
        </div>
      );
    default:
      return (
        <div className="border border-red-500 text-red-500 dark:text-white text-sm rounded-sm px-1 inline-flex items-center text-base">
          {state}
        </div>
      );
  }
};

export default FeederState;
