import { useState } from "react";
import PageWrapper from "../components/app/PageWrapper";
import {
  UserInput,
  ResetPasswordForm,
} from "../components/forms/ResetPasswordForm";
import { useAuth } from "../hooks/useAuth";
import useUserEditMutation from "../api/mutations/UserEdit";
import { useToasts } from "../hooks/useToasts";
import { SuccessAlert } from "../components/app/SuccessAlert";
import { useNavigate } from "react-router-dom";
import { Modal } from "../components/elements/Modal";

export const PasswordResetPage = () => {
  const { currentUser, updateUser } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const { mutate: userEditMutation } = useUserEditMutation();
  const navigate = useNavigate();

  const { newToast } = useToasts();

  const onSave = async (userData: UserInput) => {
    const { password } = userData;

    setError(null);
    try {
      const { data } = await userEditMutation({
        variables: {
          email: currentUser.email,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          password,
        },
      });
      if (!data.userEdit.isSuccess) {
        setError(data.userEdit.message);
      }
      const user = data.userEdit.user;
      updateUser(user);
      newToast(<SuccessAlert message="Password reset" />);
      navigate("/");
    } catch (err: any) {
      if (err.message === "NetworkError when attempting to fetch resource.") {
        setError("Error: server is currently unavailable.");
      } else {
        setError(`${err.message}`);
      }
    }
  };

  return (
    <PageWrapper>
      <Modal title="Password Reset" onClose={() => {}}>
        <div className="mt-6 ml-4">
          {error ? <div className="text-red-500">{error}</div> : ""}
          <ResetPasswordForm id="reset-password" onSave={onSave} />
        </div>
      </Modal>
    </PageWrapper>
  );
};
