import { Link } from 'react-router-dom';
import { useQueryGetNotifications, Notification } from '../api';
import { PageWrapper } from '../components/app/PageWrapper';
import { useEffect } from 'react';
import { ErrorComponent } from '../components/elements/ErrorComponent';
import { LoadingComponent } from '../components/elements/LoadingComponent';
import { NotificationList } from '../components/lists/NotificationList';

export const NotificationsPage = () => {
  const { loading, error, response } = useQueryGetNotifications();

  const notifications = response.notifications;

  if (loading) {
    return <LoadingComponent></LoadingComponent>;
  }

  if (error || !response.isSuccess) {
    return (
      <ErrorComponent error={error} message={response.message}></ErrorComponent>
    );
  }

  return (
    <PageWrapper>
      <div>
        <div className="flex">
          <div className=" flex items-center justify-between pb-3">
            <div>
              <h2 className="text-gray-600">
                <Link className="cursor-pointer font-semibold" to="/">
                  Home:
                </Link>{' '}
                <Link
                  className="cursor-pointer font-semibold"
                  to="/notifications"
                >
                  Notifications
                </Link>
              </h2>
            </div>
          </div>
        </div>
        <NotificationList notifications={notifications}></NotificationList>
      </div>
    </PageWrapper>
  );
};

export default NotificationsPage;
