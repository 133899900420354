import React, { createContext, useState, useContext, useEffect } from "react";

export type Theme = "light" | "dark";

const ThemeContext = createContext({
  setTheme: (theme: Theme) => {},
  theme: "light" as Theme,
});

const loadTheme = (): Theme => {
  return (localStorage.getItem("THEME") as Theme) || "light";
};
const saveTheme = (theme: Theme) => {
  localStorage.setItem("THEME", theme);
};

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setThemeVal] = useState<Theme>("dark");

  useEffect(() => {
    setThemeVal(loadTheme());
  }, [theme]);

  const setTheme = (theme: Theme) => {
    setThemeVal(theme);
    saveTheme(theme);
    // document.body.classList.remove("light", "dark");
    // document.body.classList.add(theme);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  return useContext(ThemeContext);
};

export const useTheme = () => {
  return useThemeContext();
};
