/**
 * The user request Account page.
 */

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useUserRequestAccountMutation from "../api/mutations/UserRequestAccount";
import { SuccessAlert } from "../components/app/SuccessAlert";
import { useToasts } from "../hooks/useToasts";

type UserRequestAccountInput = {
  email: string;
  firstName: string;
  lastName: string;
  reason: string;
};

export const UserRequestAccountPage = () => {
  const { mutate: userRequestAccountMutation } =
    useUserRequestAccountMutation();
  const { newToast } = useToasts();
  const navigate = useNavigate();

  // Validation schema for the form inputs
  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email address")
      .required("Email is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    reason: Yup.string().required("Reason is required"),
  });

  // Use react-form-hook with yup for validation
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<UserRequestAccountInput>({
    mode: "onSubmit",
    resolver: yupResolver(schema) as any,
  });

  // State of the form
  const [error, setError] = useState<string | null>(null);

  const onSubmit = async (input: UserRequestAccountInput) => {
    const { email, firstName, lastName, reason } = input;
    setError(null);
    try {
      const { data } = await userRequestAccountMutation({
        variables: {
          email,
          firstName,
          lastName,
          reason,
        },
      });
      if (!data.userRequestAccount.isSuccess) {
        setError(data.userRequestAccount.message);
        setFocus("email");
      }
      newToast(
        <SuccessAlert message="Account requested.  Please check your email." />
      );
      navigate("/login");
    } catch (err: any) {
      if (err.message === "NetworkError when attempting to fetch resource.") {
        setError("Error: server is currently unavailable.");
      } else {
        setError(`${err.message}`);
        setFocus("email");
      }
    }
  };

  return (
    <div className="flex items-center w-full min-h-screen">
      <div className="container mx-auto">
        <div className="max-w-lg mx-auto my-2 border pb-4">
          <div className="bg-gray-800 mx-auto max-w-md">
            <img alt="MASTERyard" className="mx-auto h-14" src="logo.png"></img>
          </div>
          <div className="text-center font-bold mt-4 text-gray-900 dark:text-white">
            Request Account
          </div>
          <div className="text-center">
            {error ? <p className="text-red-500">{error}</p> : ""}
          </div>
          <div className="m-7">
            <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm text-gray-800"
                >
                  Email Address
                </label>
                <input
                  autoFocus
                  autoComplete="off"
                  {...register("email")}
                  tabIndex={1}
                  type="email"
                  id="email"
                  placeholder="you@company.com"
                  className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-gray-400 dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
                />
                <p className="text-red-500">{errors.email?.message}</p>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="firstName"
                  className="block mb-2 text-sm text-gray-800"
                >
                  First Name
                </label>
                <input
                  autoComplete="off"
                  {...register("firstName")}
                  tabIndex={2}
                  type="text"
                  id="firstName"
                  placeholder="Your first name"
                  className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-gray-400 dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
                />
                <p className="text-red-500">{errors.firstName?.message}</p>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="lastName"
                  className="block mb-2 text-sm text-gray-800"
                >
                  Last Name
                </label>
                <input
                  autoComplete="off"
                  {...register("lastName")}
                  tabIndex={3}
                  type="text"
                  id="lastName"
                  placeholder="Your last name"
                  className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-gray-400  dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
                />
                <p className="text-red-500">{errors.lastName?.message}</p>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="reason"
                  className="block mb-2 text-sm text-gray-800"
                >
                  Request reason
                </label>
                <textarea
                  autoComplete="off"
                  rows={2}
                  maxLength={100}
                  {...register("reason")}
                  tabIndex={3}
                  id="reason"
                  placeholder="Reason for your account"
                  className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-gray-400  dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
                />
                <p className="text-red-500">{errors.reason?.message}</p>
              </div>

              <div className="mb-6">
                <button
                  type="submit"
                  tabIndex={4}
                  className="w-full mt-4 px-3 py-4 text-white bg-gray-800 rounded-md focus:bg-gray-600 focus:outline-none disabled:opacity-50 dark:bg-gray-500"
                  disabled={isSubmitting || !isDirty || !isValid}
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRequestAccountPage;
