import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

export const TrialPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    navigate(`/site/${params.siteId}/trials/${params.trialId}/dashboard`);
  }, [navigate, params.siteId, params.trialId]);

  return <></>;
};

export default TrialPage;
