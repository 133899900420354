/**
 * The user password reset page.
 */

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useUserResetPasswordMutation from "../api/mutations/UserResetPassword";
import { useToasts } from "../hooks/useToasts";
import { SuccessAlert } from "../components/app/SuccessAlert";

// interface UserPasswordResetPageProps {}

type UserPasswordResetInput = {
  email: string;
};

export const UserPasswordResetPage = () => {
  const { mutate: userResetPasswordMutatation } =
    useUserResetPasswordMutation();

  const { newToast } = useToasts();
  const navigate = useNavigate();

  // Validation schema for the form inputs
  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email address")
      .required("Email is required"),
  });

  // Use react-form-hook with yup for validation
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<UserPasswordResetInput>({
    mode: "onSubmit",
    resolver: yupResolver(schema) as any,
  });

  // State of the form
  const [error, setError] = useState<string | null>(null);

  const onSubmit = async (input: UserPasswordResetInput) => {
    const { email } = input;
    setError(null);
    try {
      console.log(email);
      const { data } = await userResetPasswordMutatation({
        variables: {
          email,
        },
      });
      if (!data.userResetPassword.isSuccess) {
        setError(data.userResetPassword.message);
        setFocus("email");
      }
      newToast(<SuccessAlert message="Password reset" />);
      navigate("/login");
    } catch (err: any) {
      if (err.message === "NetworkError when attempting to fetch resource.") {
        setError("Error: server is currently unavailable.");
      } else {
        setError(`${err.message}`);
        setFocus("email");
      }
    }
  };

  return (
    <div className="flex items-center w-full min-h-screen">
      <div className="container mx-auto">
        <div className="max-w-md mx-auto my-2 border pb-4">
          <div className="bg-gray-800 mx-auto max-w-md">
            <img alt="MASTERyard" className="mx-auto h-14" src="logo.png"></img>
          </div>
          <div className="text-center font-bold mt-4 text-gray-900 dark:text-white">
            Password Reset
          </div>
          <div className="text-center">
            {error ? <p className="text-red-500">{error}</p> : ""}
          </div>
          <div className="m-7">
            <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm  text-gray-900 dark:text-white"
                >
                  Email Address
                </label>
                <input
                  autoFocus
                  autoComplete="off"
                  {...register("email")}
                  tabIndex={1}
                  type="email"
                  id="email"
                  placeholder="you@company.com"
                  className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-gray-400 dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
                />
                <p className="text-red-500">{errors.email?.message}</p>
              </div>

              <div className="mb-6">
                <button
                  type="submit"
                  tabIndex={2}
                  className="w-full mt-4 px-3 py-4 text-white bg-gray-800 rounded-md focus:bg-gray-600 focus:outline-none disabled:opacity-50 dark:bg-gray-500"
                  disabled={isSubmitting || !isDirty || !isValid}
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPasswordResetPage;
