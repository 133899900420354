/**
 * A form for modifying trial pen details.
 */
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Trial, Site } from "../../api/interfaces";
import CrossIcon from "../icons/CrossIcon";

export interface TrialInput {
  id: string;
  siteId: string;
  startDate: Date;
  endDate: Date | null;
  pens: string[];
}

interface Props {
  id: string;
  sites: Site[];
  trial?: Trial;
  onSave(trial: TrialInput): void;
}

const TrialForm = ({ id, sites, trial, onSave }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [customError, setCustomError] = useState("");

  const [trialData] = useState<TrialInput>(
    trial
      ? {
          id: trial.id,
          siteId: trial.site.id,
          startDate: trial.startDate,
          endDate: trial.endDate,
          pens: trial.pens.map((p) => p.id),
        }
      : {
          id: "",
          siteId: sites[0].id,
          startDate: new Date(),
          endDate: moment().add(30, "days").toDate(),
          pens: [],
        }
  );

  const selectedPenRef = useRef(null);
  const [site, setSite] = useState<Site>(sites[0]);

  const [availablePens, setAvailablePens] = useState(
    site.pens.filter((p) => !trialData.pens.includes(p.id))
  );

  useEffect(() => {
    if (customError && trialData.pens.length) {
      setCustomError("");
    }
  }, [trialData.pens.length, customError]);

  if (sites.length === 0) {
    return <div>User does not have access to any sites</div>;
  }

  const onSubmit = handleSubmit((data) => {
    trialData.startDate = moment(data.startDate).toDate();
    trialData.endDate = moment(data.endDate).toDate();
    if (!trialData.pens.length) {
      setCustomError("At least one pen is required");
      return;
    }
    onSave(trialData);
  });

  const addDevice = (penId: string) => {
    trialData.pens.push(penId);
    setAvailablePens(site.pens.filter((p) => !trialData.pens.includes(p.id)));
  };

  const removeDevice = (penId: string) => {
    const idx = trialData.pens.findIndex((pId) => pId === penId);
    trialData.pens.splice(idx, 1);
    setAvailablePens(site.pens.filter((p) => !trialData.pens.includes(p.id)));
  };

  const changeSite = (id: string) => {
    const site = sites.find((l) => l.id === id);
    setSite(site);
    trialData.pens = [];
    setAvailablePens(site.pens);
  };

  return (
    <form id={id} className="w-fullm-auto py-2 mt-2 px-2" onSubmit={onSubmit}>
      <div v-if={customError} className="text-red-500 mb-4">
        {customError}
      </div>
      <div className="grid grid grid-cols-6 gap-6 mb-4">
        <div className="col-span-12 sm:col-span-12">
          <label className="block text-sm font-medium text-gray-900 dark:text-white">
            Site
          </label>
          <select
            onChange={({ target: { value } }) => changeSite(value)}
            className="block px-4 w-full bg-white rounded-md border-solid border-gray-300 border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-9 disabled:opacity-50  dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
            disabled={true}
          >
            {sites.map((s) => (
              <option key={s.id} value={s.id}>
                {s.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-span-6">
          <label className="block text-sm font-medium text-gray-900 dark:text-white">
            Start Date
          </label>
          <input
            className="border-solid border-gray-300 border py-2 px-4 w-full rounded text-gray-900 dark:text-white  dark:focus:border-blue-200 dark:bg-gray-800"
            name="startDate"
            type="date"
            defaultValue={moment(trialData.startDate).format("YYYY-MM-DD")}
            {...register("startDate", {
              required: "Start date is required",
              valueAsDate: true,
            })}
          />
          {errors.startDate && (
            <div className="mb-3 text-normal text-red-500 ">
              {errors.startDate.message.toString()}
            </div>
          )}
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label className="block text-sm font-medium text-gray-900 dark:text-white">
            End Date
          </label>
          <input
            className="border-solid border-gray-300 border py-2 px-4 w-full rounded text-gray-900 dark:text-white dark:focus:border-blue-200 dark:bg-gray-800"
            name="endDate"
            type="date"
            defaultValue={moment(trialData.endDate).format("YYYY-MM-DD")}
            {...register("endDate", {
              required: "End date is required",
              valueAsDate: true,
            })}
          />
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-900 dark:text-white mb-2">
          Assigned Pens ({trialData.pens.length})
        </label>
        <div className="flex gap-2">
          {availablePens.length ? (
            <select
              className="block px-4 w-full bg-white rounded-md border-solid border-gray-300 border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-9  dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
              name="pens"
              ref={selectedPenRef}
            >
              {availablePens.map((d) => (
                <option
                  className="text-gray-900 dark:text-white"
                  key={d.id}
                  value={d.id}
                >
                  {d.name}
                </option>
              ))}
            </select>
          ) : (
            ""
          )}
          {availablePens.length ? (
            <button
              type="button"
              className="text-gray-900 dark:text-white"
              onClick={() => addDevice(selectedPenRef.current.value)}
            >
              Add
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="mb-4 ml-4">
        {trialData.pens.map((penId) => {
          const pen = site.pens.find((p) => penId === p.id);
          return (
            <div key={penId} className="flex align-middle mb-2">
              <span className="text-gray-900 dark:text-white">{pen.name}</span>
              <button
                onClick={() => removeDevice(pen.id)}
                className="mx-5 text-gray-900 dark:text-white"
              >
                <CrossIcon />
              </button>
            </div>
          );
        })}
      </div>
    </form>
  );
};

export default TrialForm;
