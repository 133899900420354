import { useState } from "react";
import { User } from "../../api/interfaces";
import UserForm, { UserInput } from "../../components/forms/UserForm";
import { Modal } from "./Modal";
import { useAuth } from "../../hooks/useAuth";
import useUserEditMutation from "../../api/mutations/UserEdit";
import { useToasts } from "../../hooks/useToasts";
import { SuccessAlert } from "../app/SuccessAlert";

interface UserDetailsModalProps {
  onCancel(): void;
  onSuccess(): void;
  user: User;
}

export const UserDetailsModal = ({
  user,
  onSuccess,
  onCancel,
}: UserDetailsModalProps) => {
  const [error, setError] = useState<string | null>(null);
  const { mutate: userEditMutation } = useUserEditMutation();
  const { updateUser } = useAuth();

  const { newToast } = useToasts();

  const onSave = async (userData: UserInput) => {
    const { email, firstName, lastName, password } = userData;
    // State of the form

    setError(null);
    try {
      const { data } = await userEditMutation({
        variables: {
          email,
          firstName,
          lastName,
          password,
        },
      });
      if (!data.userEdit.isSuccess) {
        setError(data.userEdit.message);
      }
      const user = data.userEdit.user;
      updateUser(user);
      newToast(<SuccessAlert message="User details updated" />);
      onSuccess();
    } catch (err: any) {
      if (err.message === "NetworkError when attempting to fetch resource.") {
        setError("Error: server is currently unavailable.");
      } else {
        setError(`${err.message}`);
      }
    }
  };

  return (
    <Modal title="User Profile" onClose={onCancel}>
      <div>
        {error ? <div className="text-red-500">{error}</div> : ""}
        <UserForm id="user-form" user={user} onSave={onSave}></UserForm>
      </div>
    </Modal>
  );
};

export default UserDetailsModal;
