/**
 * A mutation to start feeder calibration.
 * Calls the `FeederStartCalibration` command.
 */

import { gql, useMutation } from "@apollo/client";

export interface FeederStartCalibrationInput {
  siteId: string;
  feederId: string;
}

export interface FeederStartCalibrationResponse {
  siteStartFeederCalibration: {
    isSuccess: boolean;
    message: string;
  };
}

export const FEEDER_START_CALIBRATION_MUTATION = gql`
  mutation FeederStartCalibration($siteId: ID!, $feederId: ID!) {
    siteStartFeederCalibration(input: { id: $siteId, feederId: $feederId }) {
      isSuccess
      message
    }
  }
`;

export function useFeederStartCalibrationMutation() {
  const [mutate, { data, error }] = useMutation<
    FeederStartCalibrationResponse,
    FeederStartCalibrationInput
  >(FEEDER_START_CALIBRATION_MUTATION, {
    refetchQueries: [],
  });

  return {
    mutate,
    data,
    error,
  };
}

export default useFeederStartCalibrationMutation;
