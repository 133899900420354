import ChartJS, { ChartDataset } from "chart.js/auto";
import { useCallback, useEffect, useRef } from "react";
import moment from "moment-timezone";
import { useTheme } from "../../hooks/useTheme";

type GrowthChartProps = {
  yAxisLabel?: string;
  yAxis2Label?: string;
  colors?: string[];
  colors2?: string[];
  series: GrowthDataSeries;
};
export interface GrowthData {
  date: Date;
  growth: number;
  liveweight: number;
}

export interface GrowthDataSeries {
  [pen: string]: GrowthData[];
}

export const GrowthChart = ({
  series,
  yAxisLabel = "Kg/head/day",
  yAxis2Label = "Kg",
  colors = ["#001C44", "#83C4EB", "#005CDE", "#2C5691", "#1C385E", "#003D91"],
  colors2 = ["orange", "green"],
}: GrowthChartProps) => {
  const canvasRef = useRef<any>();
  const chartRef = useRef<ChartJS<"line", number[], unknown> | null>(null);
  const { theme } = useTheme();

  const buildDataSets = useCallback(() => {
    const pens = Object.keys(series);
    const datasets: ChartDataset<any> = [];
    let i = 0;
    pens.forEach((pen) => {
      const data = series[pen].map((item) => {
        return {
          x: moment(item.date).format("DD-MM-YYYY"),
          y: item.growth,
        };
      });
      const data2 = series[pen].map((item) => {
        return {
          x: moment(item.date).format("DD-MM-YYYY"),
          y: item.liveweight,
        };
      });
      datasets.push({
        label: `${pen} Liveweight`,
        type: "line",
        data: data2,
        yAxisID: "rightYAxis",
        borderColor: colors2[i],
        backgroundColor: colors2[i],
      });
      datasets.push({
        label: `${pen} Growth`,
        type: "bar",
        data,
        backgroundColor: colors[i],
      });

      i += 1;
    });
    return datasets;
  }, [series, colors, colors2]);

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  };

  const renderChart = useCallback(() => {
    if (!canvasRef.current) return;

    chartRef.current = new ChartJS(canvasRef.current.getContext("2d"), {
      type: "line",
      data: {
        datasets: buildDataSets(),
      },
      options: {
        plugins: {
          legend: {
            display: true,
            labels: {
              pointStyle: "circle",
              usePointStyle: true,
              color: theme === "dark" ? "white" : "",
            },
            position: "bottom",
          },
        },
        borderColor: theme === "dark" ? "white" : "",
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            border: {
              color: theme === "dark" ? "white" : "",
            },
            beginAtZero: true,
            title: {
              display: true,
              text: yAxisLabel,
              color: theme === "dark" ? "white" : "",
            },
            ticks: {
              color: theme === "dark" ? "white" : "",
            },
            grid: { color: theme === "dark" ? "white" : "" },
          },
          rightYAxis: {
            border: {
              color: theme === "dark" ? "white" : "",
            },
            beginAtZero: false,
            position: "right",
            title: {
              display: true,
              text: yAxis2Label,
              color: theme === "dark" ? "white" : "",
            },
            ticks: {
              color: theme === "dark" ? "white" : "",
            },
            grid: { color: theme === "dark" ? "white" : "" },
          },
          x: {
            ticks: {
              color: theme === "dark" ? "white" : "",
            },
          },
        },
      },
    });
  }, [buildDataSets, yAxisLabel, yAxis2Label, theme]);

  useEffect(() => {
    if (chartRef.current) {
      const datasets = buildDataSets();

      chartRef.current.data.datasets = datasets;
      chartRef.current.data.labels = [];
      chartRef.current.update();
    }
  }, [series, buildDataSets]);

  useEffect(() => {
    renderChart();

    return () => destroyChart();
  }, [renderChart]);

  return <canvas ref={canvasRef} role="img"></canvas>;
};

export default GrowthChart;
