import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
  createHttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { cache } from './modules/cache';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AUTH_TOKEN_KEY, AuthProvider, clearAuthTokens } from './hooks/useAuth';
import { setContext } from '@apollo/client/link/context';
import { ThemeProvider } from './hooks/useTheme';
import { ToastProvider } from './hooks/useToasts';

const API_URI = process.env.REACT_APP_API_URI || 'http://localhost:4000';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions && extensions.code === 'UNAUTHENTICATED') {
        clearAuthTokens();
        window.location.href = '/login';
      }
      // console.log(extensions);
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });

  // console.log(networkError.exte);

  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const httpLink = createHttpLink({
  uri: API_URI,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(AUTH_TOKEN_KEY);

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,

      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: from([authLink, errorLink, httpLink]),
  resolvers: {
    Query: {
      notifications: (_root, variables, { cache }) => {
        return {
          isSuccess: true,
          message: 'Got notifications',
          notifications: [
            {
              id: '1',
              timestamp: new Date(),
              message: `Feeder 001 has been offline for 30 mins`,
              level: 'alert',
            },
          ],
        };
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AuthProvider>
        <ThemeProvider>
          <ToastProvider>
            <Router>
              <App />
            </Router>
          </ToastProvider>
        </ThemeProvider>
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
