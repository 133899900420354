import { Feeder } from '../../api/interfaces';

interface FeederTubProps {
  feeder: Feeder;
}

export const FeederTub = ({ feeder }: FeederTubProps) => {
  if (!feeder.controller) return <></>;

  const weight = (feeder.controller.tubWeight / 1000).toFixed(3);

  switch (feeder.controller.tubState) {
    case 'STABLE':
      return (
        <div className="text-sm text-center text-green-500 dark:text-white">
          <span className="border border-green-500 px-2">{weight}</span>
          <div>OK</div>
        </div>
      );
    case 'EMPTY':
      return (
        <div className="text-sm text-center text-red-500 dark:text-white">
          <span className="border border-red-500 px-2">{weight}</span>
          <div>Empty</div>
        </div>
      );
    default:
      return (
        <div className="text-sm text-center text-red-500 dark:text-white">
          <span className="border border-red-500 px-2">{weight}</span>
          <div>Error</div>
        </div>
      );
  }
};

export default FeederTub;
