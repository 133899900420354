import { useNavigate } from "react-router-dom";
import { Site, Trial } from "../../api";
import moment from "moment-timezone";
import TrialStatus from "../elements/TrialStatus";
import TrialAddModal from "../elements/TrialAddModal";
import TrialDownloadModal from "../elements/TrialDownloadModal";
import { useState } from "react";

export interface TrialListProps {
  trials: Trial[];
  site: Site;
}

export const TrialList = ({ site, trials }: TrialListProps) => {
  const navigate = useNavigate();
  const [showTrialDownloadModal, setShowTrialDownloadModal] = useState(false);
  const [selectedTrial, setSelectedTrial] = useState(null);

  const onClickViewDashboard = (trial: any) => {
    navigate(`/sites/${site.id}/trials/${trial.id}/dashboard`);
  };

  const onClickDownload = (trial: any) => {
    setSelectedTrial(trial);
    setShowTrialDownloadModal(true);
  };

  return (
    <div className="bg-white rounded-md w-full">
      {showTrialDownloadModal ? (
        <TrialDownloadModal
          onClose={() => {
            setShowTrialDownloadModal(false);
          }}
          trialId={selectedTrial.id}
        ></TrialDownloadModal>
      ) : (
        ""
      )}
      <div>
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {trials.map((trial) => (
                  <tr className="cursor-pointer" key={trial.id}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="flex items-center">
                        <div className="ml-3">
                          <div className="text-gray-900 whitespace-no-wrap font-bold">
                            {moment(trial.startDate).format("DD MMM YYYY")} to{" "}
                            {moment(trial.endDate).format("DD MMM YYYY")}
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              {trial.pens
                                .map((p: any) => p.pen.name)
                                .join(", ")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        <TrialStatus trial={trial}></TrialStatus>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <span
                        className="text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500  cursor-pointer"
                        onClick={() => onClickDownload(trial)}
                      >
                        Download Data
                      </span>
                      <span
                        className="ml-4 text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500  cursor-pointer"
                        onClick={() => onClickViewDashboard(trial)}
                      >
                        Dashboard
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
