import { useState } from "react";
import useAddTrialMutation from "../../api/mutations/TrialAdd";
import { useQueryGetSites } from "../../api/queries/GetSites";
import TrialForm, { TrialInput } from "../forms/TrialForm";
import { Modal } from "../elements/Modal";
import { Trial } from "../../api/interfaces";
import { GET_SITE_BY_ID } from "../../api";

interface TrialAddModalProps {
  onSuccess(trial: Trial): void;
  onCancel(): void;
}

export const TrialAddModal = ({ onSuccess, onCancel }: TrialAddModalProps) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { loading, error: getSitesError, response } = useQueryGetSites();

  const { mutate: addTrialMutatation } = useAddTrialMutation();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (getSitesError || !response.isSuccess) {
    return <div>{JSON.stringify(getSitesError)}</div>;
  }

  if (response.sites.length === 0) {
    return <div>User does not have access to any sites</div>;
  }

  const onSave = async (trialData: TrialInput) => {
    setSaving(true);
    try {
      const resp = await addTrialMutatation({
        variables: {
          siteId: trialData.siteId,
          startDate: trialData.startDate,
          endDate: trialData.endDate,
          pens: trialData.pens,
        },
        refetchQueries: [
          { query: GET_SITE_BY_ID, variables: { id: trialData.siteId } },
        ],
      });
      if (!resp.data.siteAddTrial.isSuccess) {
        setError(resp.data.siteAddTrial.message);
      } else {
        onSuccess(resp.data.siteAddTrial.trial);
      }
    } catch (err) {
      setError(err.toString());
    }
    setSaving(false);
  };

  const sites = response.sites;

  return (
    <Modal title="Add Trial" onClose={onCancel}>
      <div className="flex flex-col w-full">
        <div className="text-red-500" v-if={error}>
          {error}
        </div>
        <TrialForm id="trial-form" onSave={onSave} sites={sites}></TrialForm>
        <div className="py-3 sm:flex">
          <button
            form="trial-form"
            type="submit"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-400 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            disabled={saving}
          >
            {saving ? <span>Saving</span> : <span>Save</span>}
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => onCancel()}
            disabled={saving}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TrialAddModal;
