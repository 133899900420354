import moment from "moment-timezone";
import { downloadCSV } from "../../modules/data-downloads";
import {
  AnimalDailyData,
  GET_TRIAL_BY_ID_WITH_RECORDS,
  Trial,
} from "../../api";
import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

const RECORDS_FIELDS = [
  "date",
  "animalTag",
  "penName",
  "feedWeight",
  "animalWeight",
  "animalWeightChange",
  "feedTime",
  "visits",
];

interface DailyRecordsDownloaderProps {
  onDone(): void;
  onError(msg: string): void;
  trial: Trial;
}

export const DailyRecordsDownloader = ({
  onDone,
  onError,
  trial,
}: DailyRecordsDownloaderProps) => {
  const [getRecords, { error, data: response }] = useLazyQuery(
    GET_TRIAL_BY_ID_WITH_RECORDS
  );

  const loadRecords = () => {
    getRecords({ variables: { id: trial.id } });
  };

  // Load once at start
  useEffect(() => {
    console.log("[Downloader] Loading");
    loadRecords();
    return;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      console.error(`[Downloader] Error ${error}`);
      onError(error.message);
    }
  }, [error, onError]);

  const loaded = response?.trialById;

  useEffect(() => {
    if (loaded) {
      console.log("[Downloader] Loaded");

      if (!response.trialById.isSuccess) {
        onError(response.trialById.message);
        return;
      }
      const records: AnimalDailyData[] = response.trialById.trial.dailyRecords;
      // for (const pen of response.trialById.trial.pens) {
      //   records.concat(pen.records);
      // }
      const data = records.map((d: AnimalDailyData) => {
        return {
          ...d,
          date: moment(d.date).format("YYYY-MM-DD"),
          animalTag: d.animal.tag,
          penName: d.pen.name,
        };
      });
      const filename = `${trial.site.name.replace(" ", "-")}-${moment(
        trial.startDate
      ).format("YYYYMMDD")}-${moment(trial.endDate).format("YYYYMMDD")}.csv`;
      downloadCSV(data, RECORDS_FIELDS, filename);
      onDone();
    }
  }, [response, loaded, onDone, trial, onError]);

  return <div className="text-gray-900 dark:text-white">Loading...</div>;
};

export default DailyRecordsDownloader;
