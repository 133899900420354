import { useState } from "react";
import { Pen, Trial } from "../../api/interfaces";
import { Modal } from "./Modal";
import { TrialDatePicker } from "./TrialDatePicker";
import { TrialPenFilter } from "./TrialPenFilter";

interface TrialDataFilterProps {
  trial: Trial;
  onApply(pens: Pen[], startDate: Date, endDate: Date): void;
  onCancel(): void;
}

export const TrialDataFilter = ({
  trial,
  onApply,
  onCancel,
}: TrialDataFilterProps) => {
  const [selectedPens, setSelectedPens] = useState(trial.pens);
  const [selectedDates, setSelectedDates] = useState({
    startDate: trial.startDate,
    endDate: trial.endDate,
  });

  const onSelectedDates = (startDate: Date, endDate: Date) => {
    setSelectedDates({
      startDate,
      endDate,
    });
  };

  const onSelectedPens = (pens: Pen[]) => {
    setSelectedPens(pens);
  };

  return (
    <div>
      <Modal title="Filter Data" onClose={onCancel}>
        <div className="mb-8">
          <TrialDatePicker
            startDate={trial.startDate}
            endDate={trial.endDate}
            onChange={onSelectedDates}
          ></TrialDatePicker>
          <TrialPenFilter
            pens={trial.pens}
            onSelect={onSelectedPens}
          ></TrialPenFilter>
        </div>
        <div>
          <button
            type="button"
            onClick={() =>
              onApply(
                selectedPens,
                selectedDates.startDate,
                selectedDates.endDate
              )
            }
            disabled={selectedPens.length === 0}
            className="mr-2 px-3 py-2 text-white bg-gray-800 dark:bg-gray-400 rounded-md focus:bg-gray-600 focus:outline-none disabled:opacity-50"
          >
            Apply
          </button>
          <button
            type="button"
            onClick={() => onCancel()}
            className="px-3 py-2 text-white bg-gray-800 dark:bg-gray-500 rounded-md focus:bg-gray-600 focus:outline-none disabled:opacity-50"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};
