import Version from "./Version";

export const PageFooter = () => {
  const API_URL = process.env.REACT_APP_API_URI;

  return (
    <footer className="relative bottom-0 left-0 p-2 flex mt-8">
      <div className="ml-auto mr-4 flex text-xs text-gray-500 sm:text-center dark:text-gray-100">
        <span className="mr-2">{API_URL}</span>
        <Version></Version>
      </div>
    </footer>
  );
};

export default PageFooter;
