import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated, currentUser } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (
    currentUser.passwordResetRequired &&
    location.pathname !== "/reset-password"
  ) {
    return <Navigate to="/reset-password" />;
  }
  return children;
};

export default ProtectedRoute;
