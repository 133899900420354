import { useEffect, useRef, useState } from "react";
import { ArrowIcon } from "../icons/ArrowIcon";

export interface MultiSelectDropdownPops {
  formFieldName: string;
  options: string[];
  onChange(selections: string[]): void;
}

export const MultiSelectDropdown = ({
  formFieldName,
  options,
  onChange,
}: MultiSelectDropdownPops) => {
  const [selectedOptions, setSelectedOptions] = useState(options);
  const [text, setText] = useState<string>(`All ${formFieldName}`);
  const [showDropdown, setShowDropdown] = useState(false);

  const ref = useRef();

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      if (ref.current && !(ref.current as any).contains(event.target)) {
        setShowDropdown(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    generateText(selectedOptions);
  });

  const generateText = (opts: string[]) => {
    if (opts.length === options.length) {
      setText(`All ${formFieldName}`);
    } else if (opts.length === 0) {
      setText(`No ${formFieldName} selected`);
    } else if (opts.length <= 2) {
      setText(opts.join(","));
    } else {
      setText(opts.slice(0, 2).join(",") + "...");
    }
  };

  const handleChange = (e: any) => {
    const isChecked = e.target.checked;
    const option = e.target.value;

    const selectedOptionSet = new Set(selectedOptions);

    if (isChecked) {
      selectedOptionSet.add(option);
    } else {
      selectedOptionSet.delete(option);
    }

    const newSelectedOptions = Array.from(selectedOptionSet);

    generateText(newSelectedOptions);

    setSelectedOptions(newSelectedOptions);

    onChange(newSelectedOptions);
  };

  return (
    <div ref={ref} className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white dark:bg-gray-800 px-3 py-2 text-md text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          {text}
          <ArrowIcon></ArrowIcon>
        </button>
      </div>
      {showDropdown ? (
        <div
          className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black dark:ring-gray-700 ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          {options.map((option, i) => {
            return (
              <div className="py-1" role="none" key={option}>
                <label className="text-gray-900 dark:text-white flex whitespace-nowrap cursor-pointer px-2 py-1 transition-colors hover:bg-blue-100 dark:hover:bg-gray-500">
                  <input
                    type="checkbox"
                    name={formFieldName}
                    value={option}
                    onChange={handleChange}
                    checked={selectedOptions.includes(option)}
                    className="cursor-pointer text-gray-900 dark:text-white"
                  />
                  <span className="ml-1 text-gray-900 dark:text-white">
                    {option}
                  </span>
                </label>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );

  // return (
  //   <div>
  //     <label ref={ref} className="relative">
  //       <div
  //         onClick={() => setShowDropdown(true)}
  //         className="text-gray-900 dark:text-white cursor-pointer rounded-md inline-flex border rounded px-5 py-2"
  //       >
  //         <span className="mr-2">{text}</span> <ArrowIcon></ArrowIcon>
  //       </div>
  //       {showDropdown ? (
  //         <div className="w-full mt-1 absolute rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black dark:ring-gray-700 ring-opacity-5 focus:outline-none">
  //           <ul>
  //             {options.map((option, i) => {
  //               return (
  //                 <li key={option}>
  //                   <label className="text-gray-900 dark:text-white flex whitespace-nowrap cursor-pointer px-2 py-1 transition-colors hover:bg-blue-100 dark:hover:bg-blue-400">
  //                     <input
  //                       type="checkbox"
  //                       name={formFieldName}
  //                       value={option}
  //                       onChange={handleChange}
  //                       className="cursor-pointer text-gray-900 dark:text-white"
  //                     />
  //                     <span className="ml-1 text-gray-900 dark:text-white">
  //                       {option}
  //                     </span>
  //                   </label>
  //                 </li>
  //               );
  //             })}
  //           </ul>
  //         </div>
  //       ) : (
  //         ""
  //       )}
  //     </label>
  //   </div>
  // );
};
