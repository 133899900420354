import ChartJS from "chart.js/auto";
import { useCallback, useEffect, useRef } from "react";
import moment from "moment-timezone";
import { useTheme } from "../../hooks/useTheme";

type IntakeChartProps = {
  yAxisLabel?: string;
  colors?: string[];
  series: IntakeDataSeries;
};

export interface IntakeData {
  date: Date;
  intake: number;
}

export interface IntakeDataSeries {
  [pen: string]: IntakeData[];
}

export const IntakeChart = ({
  series,
  yAxisLabel = "Mean intake (kg)",
  colors = ["#001C44", "#83C4EB", "#005CDE", "#2C5691", "#1C385E", "#003D91"],
}: IntakeChartProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<ChartJS<"bar", number[], unknown> | null>();
  const { theme } = useTheme();

  const buildDataSets = useCallback(() => {
    const pens = Object.keys(series);
    const datasets: any = [];
    let i = 0;
    pens.forEach((pen) => {
      const data = series[pen].map((item) => {
        return {
          x: moment(item.date).format("DD-MM-YYYY"),
          y: item.intake,
        };
      });
      datasets.push({
        label: pen,
        data,
        backgroundColor: colors[i],
      });
      i += 1;
    });
    return datasets;
  }, [series, colors]);

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  };

  const renderChart = useCallback(() => {
    if (!canvasRef.current) return;

    chartRef.current = new ChartJS(canvasRef.current.getContext("2d"), {
      type: "bar",
      data: {
        datasets: buildDataSets(),
      },

      options: {
        plugins: {
          legend: {
            display: true,
            labels: {
              pointStyle: "circle",
              usePointStyle: true,
              color: theme === "dark" ? "white" : "",
            },
            position: "bottom",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        borderColor: theme === "dark" ? "white" : "",
        scales: {
          y: {
            border: {
              color: theme === "dark" ? "white" : "",
            },
            beginAtZero: true,
            title: {
              display: true,
              text: yAxisLabel,
              color: theme === "dark" ? "white" : "",
            },
            ticks: {
              color: theme === "dark" ? "white" : "",
            },
            grid: {
              color: theme === "dark" ? "white" : "",
            },
          },
          x: {
            ticks: {
              color: theme === "dark" ? "white" : "",
            },
            grid: {
              color: theme === "dark" ? "white" : "",
            },
          },
        },
      },
    });
  }, [yAxisLabel, buildDataSets, theme]);

  useEffect(() => {
    if (chartRef.current) {
      const datasets = buildDataSets();

      chartRef.current.data.datasets = datasets;
      chartRef.current.data.labels = [];
      chartRef.current.update();
    }
  }, [series, buildDataSets]);

  useEffect(() => {
    renderChart();

    return () => destroyChart();
  }, [renderChart]);

  return <canvas ref={canvasRef} role="img"></canvas>;
};

export default IntakeChart;
