import { gramsToKg } from "../../modules/utils";
import { NumberCard } from "./NumberCard";

export interface TrialSummaryData {
  animalsOnFeed: number;
  meanGrowthRate: number;
  meanLiveweight: number;
  meanFeedConsumed: number;
  totalRecords: number;
  recordsPerDay: number;
}

interface Props {
  data: TrialSummaryData;
}

export const TrialSummaryDataTable = ({ data }: Props) => {
  return (
    <div className="grid gap-4 lg:gap-8 grid-cols-6 p-2">
      <NumberCard name="Visits Per Day" value={data.recordsPerDay}></NumberCard>
      <NumberCard name="Animals" value={data.animalsOnFeed}></NumberCard>
      <NumberCard
        name="Intake"
        value={gramsToKg(data.meanFeedConsumed, 2)}
        extra="kg/head/day"
      ></NumberCard>
      <NumberCard
        name="Growth"
        value={gramsToKg(data.meanGrowthRate, 2)}
        extra="kg/head/day"
      ></NumberCard>
      <NumberCard
        name="Mean Liveweight"
        value={gramsToKg(data.meanLiveweight, 1)}
        extra="kg"
      ></NumberCard>
    </div>
  );
  // // return (
  // //   <div className="border py-4 px-4 rounded-md w-1/2">
  // //     <h1 className="font-xl font-bold text-gray-800 mb-4">Summary Data</h1>
  // //     <div className="grid grid-cols-4 gap-1">
  // //       <div className="col-span-1 text-gray-700">Animals on feed</div>
  // //       <div className="col-span-1 text-gray-500">{data.animalsOnFeed}</div>
  // //       <div className="col-span-1 text-gray-700">Average growth rate</div>
  // //       <div className="col-span-1 text-gray-500">
  // //         {gramsToKg(data.meanGrowthRate)} kg/head/day
  // //       </div>
  // //       <div className="col-span-1 text-gray-700">Average liveweight</div>
  // //       <div className="col-span-1 text-gray-500">
  // //         {gramsToKg(data.meanLiveweight)} kg
  // //       </div>
  // //       <div className="col-span-1 text-gray-700">Average feed consumption</div>
  // //       <div className="col-span-1 text-gray-500">
  // //         {gramsToKg(data.meanFeedConsumed)} kg/head/day
  // //       </div>
  // //       <div className="col-span-1 text-gray-700">Visits</div>
  // //       <div className="col-span-1 text-gray-500">{data.totalRecords}</div>
  // //       <div className="col-span-1 text-gray-700">Visits per day</div>
  // //       <div className="col-span-1 text-gray-500">{data.recordsPerDay}</div>
  // //     </div>
  // //   </div>
  // );
};
