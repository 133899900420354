import { useState } from 'react';
import { AlertsModal } from './AlertsModal';
import { useQueryGetNotifications } from '../../api';
import { useNavigate } from 'react-router-dom';

export const Alerts = () => {
  const [showAlertsModal, setShowAlertsModal] = useState(false);
  const { loading, error, response } = useQueryGetNotifications();
  const navigate = useNavigate();

  if (loading) {
    return <></>;
  }

  if (error || !response.isSuccess) {
    return <></>;
  }

  const notifications = response.notifications;

  if (notifications.length === 0) {
    return <></>;
  }

  const onClick = () => {
    navigate('/notifications');
  };

  return (
    <div>
      {showAlertsModal ? (
        <AlertsModal
          onCancel={() => setShowAlertsModal(false)}
          onSuccess={() => setShowAlertsModal(false)}
          alerts={notifications}
        />
      ) : (
        ''
      )}
      <button
        type="button"
        onClick={() => onClick()}
        className="mr-2 inline-flex items-center px-5 py-2 text-sm font-medium text-center text-white bg-red-500 rounded-md hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
      >
        Alerts
        <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-red-800 bg-red-200 rounded-full">
          {notifications.length}
        </span>
      </button>
    </div>
  );
};
