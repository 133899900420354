/**
 * A form for modifying user details.
 */
import { useState } from "react";
import { useForm } from "react-hook-form";
import { User } from "../../api/interfaces";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export interface UserInput {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirmation: string;
}

interface Props {
  id: string;
  user?: User;
  onSave(user: UserInput): void;
}

// Validation schema for the form inputs
const schema = Yup.object().shape({
  // email: Yup.string()
  //   .email("Must be a valid email address")
  //   .required("Email is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum."),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const UserForm = ({ id, user, onSave }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserInput>({
    mode: "onChange",
    resolver: yupResolver(schema) as any,
  });

  const [customError] = useState("");

  const onSubmit = handleSubmit((data) => {
    userData.firstName = data.firstName;
    userData.lastName = data.lastName;
    userData.password = data.password;
    onSave(userData);
  });

  const [userData] = useState<UserInput>(
    user
      ? {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          password: "",
          passwordConfirmation: "",
        }
      : {
          email: "",
          firstName: "",
          lastName: "",
          password: "",
          passwordConfirmation: "",
        }
  );

  return (
    <form id={id} className="py-2 mt-2 px-2" onSubmit={onSubmit}>
      <div v-if={customError} className="text-red-500 mb-4">
        {customError}
      </div>
      <div className="grid grid grid-cols-12 gap-6 mb-4">
        <div className="col-span-12 sm:col-span-12">
          <label
            htmlFor="email"
            className="block mb-2 text-sm text-gray-900 dark:text-white"
          >
            Email Address
          </label>
          <input
            autoFocus
            autoComplete="new-password"
            {...register("email")}
            tabIndex={0}
            disabled={true}
            type="email"
            id="email"
            value={userData.email}
            placeholder="you@company.com"
            className="block px-4 w-full bg-white rounded-md border-solid border-gray-300 border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-9 disabled:opacity-50  dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
          />
          <p className="text-red-500">{errors.email?.message}</p>
        </div>
        <div className="col-span-6 sm:col-span-6">
          <label
            htmlFor="firstName"
            className="block mb-2 text-sm text-gray-900 dark:text-white"
          >
            First Name
          </label>
          <input
            {...register("firstName")}
            tabIndex={1}
            type="text"
            id="firstName"
            defaultValue={userData.firstName}
            placeholder="Your first name"
            className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-gray-400 dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
          />
          <p className="text-red-500">{errors.firstName?.message}</p>
        </div>
        <div className="col-span-6 sm:col-span-6">
          <label
            htmlFor="lastName"
            className="block mb-2 text-sm text-gray-900 dark:text-white"
          >
            Last Name
          </label>
          <input
            {...register("lastName")}
            tabIndex={3}
            type="text"
            id="lastName"
            defaultValue={userData.lastName}
            placeholder="Your last name"
            className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-gray-400 dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
          />
          <p className="text-red-500">{errors.lastName?.message}</p>
        </div>
        <div className="col-span-6 sm:col-span-6">
          <label
            htmlFor="lastName"
            className="block mb-2 text-sm text-gray-900 dark:text-white"
          >
            Password
          </label>
          <input
            autoComplete="new-password"
            {...register("password")}
            tabIndex={3}
            type="password"
            id="password"
            className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-gray-400 dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
          />
          <p className="text-red-500">{errors.password?.message}</p>
        </div>
        <div className="col-span-6 sm:col-span-6">
          <label
            htmlFor="passwordConfirmation"
            className="block mb-2 text-sm text-gray-900 dark:text-white"
          >
            Confirm password
          </label>
          <input
            autoComplete="off"
            {...register("passwordConfirmation")}
            tabIndex={3}
            type="password"
            id="passwordConfirmation"
            className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-gray-400 dark:focus:border-blue-200 dark:bg-gray-800 dark:text-white"
          />
          <p className="text-red-500">{errors.passwordConfirmation?.message}</p>
        </div>
      </div>
      <div className="col-span-6 sm:col-span-6">
        <button
          type="submit"
          tabIndex={4}
          className="w-full mt-4 px-3 py-4 text-white bg-gray-800 rounded-md focus:bg-gray-600 focus:outline-none disabled:opacity-50 dark:bg-gray-500"
          // disabled={isSubmitting || !isDirty || !isValid}
        >
          {isSubmitting ? "Submitting" : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default UserForm;
