/**
 * A mutation to logout.
 * Calls the `Logout` command.
 */

import { gql, useMutation } from "@apollo/client";

export interface LogoutInput {}

export interface LogoutResponse {
  userLogout: {
    isSuccess: boolean;
    message: string;
  };
}

export const LOGOUT = gql`
  mutation Logout($id: ID) {
    userLogout(input: { id: $id }) {
      isSuccess
      message
    }
  }
`;

export function useLogoutMutation() {
  const [mutate, { data, error }] = useMutation<LogoutResponse, LogoutInput>(
    LOGOUT,
    {
      refetchQueries: [],
    }
  );
  return {
    mutate,
    data,
    error,
  };
}

export default useLogoutMutation;
